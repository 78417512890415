<template>
  <div class="cont">
    <div v-if="organization">
      <div class="topDetails">
        <div class="nameLogoAddress">
          <div class="logo">
            <img :src="organization.organization_logo" alt="Logo">
          </div>
          <div class="nameAddress">
            <h2>{{ organization.organization_name }}</h2>
            <h5>{{ organization.organization_address }}</h5>
          </div>
        </div>
        <div class="actionBtns">
          <button class="editBtn" @click="handleEdit">
            <a-icon type="edit" /> एडिट
          </button>
          <!-- <button class="deleteBtn" @click="handleDelete">
            <a-icon type="delete" /> डिलिट
          </button> -->
          <a-popconfirm title="के तपाईं पक्का यो संस्था मेट्न चाहनुहुन्छ?" ok-text="हो" cancel-text="होइन"
            @confirm="handleDeleteOrg" placement="bottom">
            <button class="deleteBtn">
              <a-icon type="delete" /> डिलिट
            </button>
          </a-popconfirm>
        </div>
      </div>
      <div class="bottomDetails">
        <div class="leftBottomDetails">
          <h3>विवरण</h3>
          <p style="font-size: 15px;">{{ organization.organization_description }}</p>
        </div>
        <div class="middleBottomDetails">
          <h3>संपर्क विवरण</h3>
          <div class="contactInfo">
            <a-icon style="font-size: larger; color: #3B5CA5;" type="mail" />
            <span style="font-weight: bold; margin: 0px 8px;">ईमेल: </span>
            <a class="links" :href="'mailto:' + organization.organization_email" target="_blank">
              {{ organization.organization_email }}
            </a>
          </div>
          <div class="contactInfo">
            <a-icon style="font-size: larger; color: #3B5CA5;" type="phone" />
            <span style="font-weight: bold; margin: 0px 8px;">फोन नं: </span>
            <a class="links" :href="'tel:' + organization.organization_contact" target="_blank">
              {{ organization.organization_contact }}
            </a>
          </div>
          <div class="contactInfo">
            <a-icon style="font-size: larger; color: #3B5CA5;" type="link" />
            <span style="font-weight: bold; margin: 0px 8px;">वेबसाइट: </span>
            <a class="links" :href="organization.organization_website_url" target="_blank">
              {{ organization.organization_website_url }}
            </a>
          </div>
        </div>
        <div class="rightBottomDetails">
          <h3>अन्य विवरण</h3>
          <!-- <div class="contactInfo">
                        <a-icon style="font-size: larger; color: #3B5CA5;" type="calendar" />
                        <span style="font-weight: bold; margin: 0px 8px;">सम्झौता मिति: </span>
                        <span>{{ organization.agreement_date? organization.agreement_date: 'N/A' }}</span>
                    </div>
                    <div class="contactInfo">
                        <a-icon style="font-size: larger; color: #3B5CA5;" type="history" />
                        <span style="font-weight: bold; margin: 0px 8px;">सम्झौता अवधि: </span>
                        <span>{{ organization.agreement_duration ?
                            convertToNepaliNumerals(organization.agreement_duration): '0' }} वर्ष</span>
                    </div> -->
          <div class="contactInfo">
            <a-icon style="font-size: larger; color: #3B5CA5;" type="qrcode" />
            <span style="font-weight: bold; margin: 0px 8px;">QR कोड: </span>
            <span>{{ organization.organization_QR_code ? 'Click' : 'N/A' }}</span>
          </div>
        </div>

      </div>
      <div class="branchList">
        <h3>शाखाहरू</h3>
        <a-table class="table" :columns="columns" :dataSource="organization.branches" :rowKey="record => record.id">
          <template slot="location" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text }}</span>
          </template>
          <template slot="service_type" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text.toLowerCase() === 'free' ? 'निःशुल्क' :
              'स:शुल्क' }}</span>
          </template>
          <template slot="toilet_type" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text.toLowerCase() === 'male' ? 'पुरुष' :
              text.toLowerCase() ===
                'female' ?
                'महिला' :
                'सबै' }}</span>
          </template>
          <template slot="disabled_status" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text ? 'हो' : 'होइन' }}</span>
          </template>
          <template slot="category" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text.toLowerCase() === 'private' ? 'निजी' :
              'सार्वजनिक' }}</span>
          </template>
          <template slot="status" slot-scope="text">
            <span style="text-transform: capitalize;">{{ text.toLowerCase() === 'active' ? 'सक्रिय' :
              'निष्क्रिय'
              }}</span>
          </template>
          <template slot="action" slot-scope="record">
            <div style="display: flex; flex-wrap: wrap; gap: 0px 8px">
              <div style="cursor: pointer;" @click="viewBranch(record.id)"><a-icon
                  style="color: cornflowerblue; font-size: large;" type="eye" /></div>
              <!-- <div style="cursor: pointer;" @click="handleDelete(record.id)"><a-icon
                                      style="color: red; font-size: large;" type="delete" /></div> -->
              <a-popconfirm title="के तपाईं पक्का यो शौचालय मेट्न चाहनुहुन्छ?" ok-text="हो" cancel-text="होइन"
                @confirm="handleDeleteBranch(record.id)" placement="bottom">
                <div style="cursor: pointer;"><a-icon style="color: red; font-size: large;" type="delete" /></div>
              </a-popconfirm>
            </div>
          </template>
        </a-table>
      </div>
    </div>
    <!-- <div v-else>
            <h2>लोड हुँदैछ, कृपया पर्खनुहोस्</h2>
        </div> -->
    <a-spin v-else class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
  </div>
</template>

<script>
import { Table, Icon } from 'ant-design-vue';
import PublicShauchalaya from '../../services/PublicShauchalaya';
import { message } from "ant-design-vue";

export default {
  props: ['id'],
  components: {
    'a-table': Table,
    'a-icon': Icon
  },
  data() {
    return {
      organization: null,
      columns: [
        {
          title: 'ठेगाना',
          dataIndex: 'location',
          key: 'location',
          scopedSlots: { customRender: 'location' }
        },
        {
          title: 'सेवा प्रकार',
          dataIndex: 'service_type',
          key: 'service_type',
          scopedSlots: { customRender: 'service_type' }
        },
        {
          title: 'शौचालय प्रकार',
          dataIndex: 'toilet_type',
          key: 'toilet_type',
          scopedSlots: { customRender: 'toilet_type' }
        },
        {
          title: 'अपांगमैत्री',
          dataIndex: 'disabled_status',
          key: 'disabled_status',
          scopedSlots: { customRender: 'disabled_status' }
        },
        {
          title: 'श्रेणी',
          dataIndex: 'category',
          key: 'category',
          scopedSlots: { customRender: 'category' }
        },
        {
          title: 'स्थिति',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: 'कार्यहरू',
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    };
  },
  mounted() {
    this.fetchOrganization();
  },
  methods: {
    fetchOrganization() {
      PublicShauchalaya.getIndividualOrganization(this.id)
        .then(response => {
          this.organization = response.data.data;
        })
        .catch(error => {
          console.error('Error fetching organization:', error);
        });
    },
    handleDeleteOrg() {
      // if (confirm('तपाईं पक्का यो संस्था मेट्न चाहनुहुन्छ?')) {
      PublicShauchalaya.deleteOrganization(this.id)
        .then(response => {
          this.$router.push('/organization/register');
        })
        .catch(error => {
          console.error('Error deleting branch:', error);
        });
      //   }
    },
    handleEdit() {
      this.$router.push(`/organization/edit/${this.id}`);
    },
    viewBranch(id) {
      this.$router.push(`/branch/${id}`);
    },
    handleDeleteBranch(id) {
      // if (confirm("तपाईं पक्का यो शाखा मेट्न चाहनुहुन्छ?")) {
      PublicShauchalaya.deleteBranch(id)
        .then((response) => {
          this.fetchData();
          message.success("शौचालय सफलतापूर्वक मेटिएको छ!");
        })
        .catch((error) => {
          message.error("शौचालय मेटाउन समस्या आएको छ!");
          console.error("Error deleting branch:", error);
        });
      // }
    },
    convertToNepaliNumerals(n) {
      return PublicShauchalaya.convertToNepaliNumerals(n);
    },

  }
};
</script>

<style scoped>
.cont {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px;
}

.topDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.nameLogoAddress {
  display: flex;
  align-items: center;
}

.logo img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 10px;
  /* border: 0.5px solid rgb(170, 170, 170); */
}

.nameAddress {
  display: flex;
  flex-direction: column;
}

.actionBtns {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actionBtns button {
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100px;
  gap: 5px;
}

.editBtn {
  color: #3B5CA5;
  border: 1px solid #3B5CA5;
}

.deleteBtn {
  color: #fb5e62;
  border: 1px solid #fb5e62;
}

.bottomDetails {
  display: flex;
  align-content: center;
  width: 100%;
}

.leftBottomDetails {
  width: 40%;
  padding: 8px 16px;
}

.middleBottomDetails {
  width: 30%;
  padding: 8px 16px;
}

.rightBottomDetails {
  width: 30%;
  padding: 8px 16px;
}

.contactInfo {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin: 4px 0px;
}

.links {
  text-decoration: none;
  color: black;
}

.links:hover {
  text-decoration: underline;
  color: #3B5CA5;
}

.table {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
}

.branchList {
  margin-top: 20px;
}

.ant-table-tbody>tr>td {
  text-transform: capitalize;
}

.loader {
  display: flex;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 768px) {
  .topDetails {
    flex-direction: column;
  }

  .bottomDetails {
    flex-direction: column;
  }

  .leftBottomDetails,
  .middleBottomDetails,
  .rightBottomDetails {
    width: 100%;
    padding: 8px 16px;
  }
}
</style>
