<template>
    <div>
        <div id="displayMap" style="height: 600px; width: 100%; border-radius: 10px;"></div>
        <div id="threedimension"></div>
    </div>
</template>

<script>
export default {
    name: 'DisplayMap',
    props: {
        latitude: {
            required: true,
        },
        longitude: {
            required: true,
        },
    },
    data() {
        return {
            lat: parseFloat(this.latitude),
            long: parseFloat(this.longitude),
        }
    },
    mounted() {
        this.initializeMap();
    },
    methods: {
        initializeMap() {

            const galliMapsObject = {
                accessToken: 'c9c7a4c0-2834-4282-94f7-f4c79c40ab0a',
                map: {
                    container: 'displayMap',
                    center: [this.lat, this.long],
                    oom: 12,
                    maxZoom: 25,
                    minZoom: 5,
                },
                pano: { container: 'threedimension', },
            };

            // Initialize Gallimaps Object
            const map = new GalliMapPlugin(galliMapsObject);

            // Check if the map was created successfully
            if (!map) {
                console.error('Failed to initialize the map.');
                return;
            }

            // Pin Marker Options
            let pinMarkerObject = {
                color: '#C8102E',
                draggable: false,
                latLng: [this.lat, this.long],
            };

            // Display a pin marker on the map
            let marker = map.displayPinMarker(pinMarkerObject);

            // Check if the marker was created successfully
            if (!marker) {
                console.error('Failed to display the pin marker.');
            }
        },
    },
};
</script>

<style scoped>
#display-map {
    height: 400px;
    width: 100%;
}
</style>
