<template>
  <div class="cont">
    <div class="formDiv" :key="componentKey">
      <div style="justify-content: start" class="heading">
        <a-icon style="color: #3B5CA5" class="headerIcon" type="form" />
        नयाँ दर्ता
      </div>
      <a-form-model class="form" @submit="handleSubmit" layout="vertical">
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.organization_id ? 'error' : ''"
              :help="
                validation.organization_id ? 'शौचालयको मूल संस्था आवश्यक छ' : ''
              "
              v-bind="formItemLayout"
              label="शौचालयको मूल संस्था"
            >
              <a-select
                show-search
                placeholder="शौचालयको मूल संस्था चयन गर्नुहोस्"
                option-filter-prop="children"
                :filter-option="filterOption"
                @change="handleSelectOrgChange"
                v-model="formState.organization_id"
              >
                <a-select-option
                  v-for="org in organizations"
                  :key="org.id"
                  :value="org.id"
                >
                  {{ org.organization_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.location ? 'error' : ''"
              :help="validation.location ? 'शौचालयको ठेगाना आवश्यक छ' : ''"
              v-bind="formItemLayout"
              label="शौचालयको ठेगाना"
            >
              <a-input v-model="formState.location" type="text" required />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.toilet_type ? 'error' : ''"
              :help="validation.toilet_type ? 'शौचालयको प्रकार आवश्यक छ' : ''"
              v-bind="formItemLayout"
              label="शौचालयको प्रकार"
            >
              <a-select v-model="formState.toilet_type" required>
                <a-select-option value="male">पुरुष</a-select-option>
                <a-select-option value="female">महिला</a-select-option>
                <a-select-option value="all">सबै</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.category ? 'error' : ''"
              :help="validation.category ? 'शौचालयको वर्ग आवश्यक छ' : ''"
              v-bind="formItemLayout"
              label="शौचालयको वर्ग"
            >
              <a-select v-model="formState.category" required>
                <a-select-option value="private">निजी</a-select-option>
                <a-select-option value="public">सार्वजनिक</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.status ? 'error' : ''"
              :help="validation.status ? 'शौचालयको स्थिति आवश्यक छ' : ''"
              v-bind="formItemLayout"
              label="शौचालयको स्थिति"
            >
              <a-select v-model="formState.status" required>
                <a-select-option value="1">सक्रिय</a-select-option>
                <a-select-option value="0">निष्क्रिय</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.service_type ? 'error' : ''"
              :help="validation.service_type ? 'सेवाको प्रकार आवश्यक छ' : ''"
              v-bind="formItemLayout"
              label="सेवाको प्रकार"
            >
              <a-select v-model="formState.service_type" required>
                <a-select-option value="paid">श:शुल्क</a-select-option>
                <a-select-option value="free">नि:शुल्क</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.disabled_status ? 'error' : ''"
              :help="
                validation.disabled_status
                  ? 'अपाङ्गहरूले प्रयोग गर्न सक्छन्?'
                  : ''
              "
              v-bind="formItemLayout"
              label="अपाङ्गहरूले प्रयोग गर्न सक्छन्?"
            >
              <a-select v-model="formState.disabled_status" required>
                <a-select-option value="1">सक्छन्</a-select-option>
                <a-select-option value="0">सक्दैनन्</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              :validateStatus="validation.location ? 'error' : ''"
              :help="validation.location ? 'नक्सामा चयन गर्नुहोस्' : ''"
              v-bind="formItemLayout"
              label="नक्सामा चयन गर्नुहोस्"
            >
              <a-button
                style="width: 100%; text-align: left"
                @click="showMapModal"
                >नक्सा खोल्नुहोस्</a-button
              >
              <span
                style="margin: 0 4px"
                v-if="formState.latitude && formState.longitude"
                >चयन भयो</span
              >
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="वडा नं:">
              <a-input disabled v-model="formState.ward" type="text" readonly />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="नगरपालिका/गाउँपालिका"
            >
              <a-input
                disabled
                v-model="formState.municipality"
                type="text"
                readonly
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="सञ्चालन गर्ने व्यक्तिको नाम"
            >
              <a-input v-model="formState.focal_person" type="text" required />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="सम्पर्क नम्बर">
              <a-input
                v-model="formState.focal_person_contact"
                type="text"
                required
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="24">
            <a-form-model-item v-bind="formItemLayout" label="सम्झौता भएको छ?">
              <a-radio-group
                v-model="formState.agreement_done"
                @change="handleAgreementChange"
              >
                <a-radio value="1">छ</a-radio>
                <a-radio value="0">छैन</a-radio>
                <a-radio :value="null">लागू हुँदैन</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="formState.agreement_done === '1'" :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item label="सम्झौता मिति" prop="agreement_date">
              <VNepaliDatePicker
                calenderType="Nepali"
                :placeholder="branchAgreementPlaceholder"
                format="yyyy-mm-dd"
                classValue="nepalidatepicker"
                v-on:change="dateChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="सम्झौता अवधि">
              <a-input
                v-model="formState.agreement_duration"
                type="number"
                min="0"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="24">
            <a-form-model-item
              v-bind="formItemLayout"
              label="शौचालयका तस्बिरहरू अपलोड गर्नुहोस्"
            >
              <ImageUploader
                ref="imageUploader"
                @update-images="updateImages"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button
            style="background-color: #2c68aa"
            type="primary"
            html-type="submit"
            :loading="isSubmitting"
          >
            दर्ता गर्नुहोस्
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="tableDiv">
      <div class="heading">
        <div class="subHeading">
          <a-icon
            style="color: #3B5CA5"
            class="headerIcon"
            type="unordered-list"
          />
          शौचालयहरूको सूची
        </div>
        <div
          style="cursor: pointer"
          @click="() => $router.push('/toilet/list')"
          class="subHeading"
        >
          <a-icon style="color: red" class="headerIcon" type="eye" /> पूर्ण सूची
          हेर्नुहोस्
        </div>
      </div>
      <div class="table">
        <ListOfToilets />
      </div>
    </div>
    <SelectMap
      :key="mapComponentKey"
      :visible.sync="isMapModalVisible"
      @locationSelected="handleLocationSelected"
    />
  </div>
</template>

<script>
import PublicShauchalaya from "../../services/PublicShauchalaya";
import ImageUploader from "../../components/Branch/ImageUploader.vue";
import ListOfToilets from "./ListOfToilets.vue";
import SelectMap from "../../components/Maps/SelectMap.vue";
import VNepaliDatePicker from "v-nepalidatepicker";
import axios from "axios";
import { message } from "ant-design-vue";

export default {
  components: {
    ImageUploader,
    VNepaliDatePicker,
    ListOfToilets,
    SelectMap,
  },
  data() {
    return {
      formState: this.getInitialFormState(),
      fileList: [],
      organizations: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      },
      validation: this.getInitialValidationState(),
      componentKey: 0,
      mapComponentKey: 1,
      isSubmitting: false,
      isMapModalVisible: false,
      branchAgreementPlaceholder: "००००-००-००",
    };
  },
  mounted() {
    this.fetchOrganizations();
  },
  // watch: {
  //   'formState.latitude'(newVal) {
  //     console.log('Latitude changed:', newVal);
  //     console.log('ward: ', this.formState.ward);
  //     console.log('municipality: ', this.formState.municipality);
  //   },
  //   'formState.longitude'(newVal) {
  //     console.log('Longitude changed:', newVal);
  //   },
  // },
  methods: {
    getInitialFormState() {
      return {
        organization_id: null,
        toilet_type: "",
        category: "",
        status: "",
        disabled_status: "",
        service_type: "",
        location: "",
        latitude: "",
        longitude: "",
        ward: "",
        municipality: "",
        focal_person: "",
        focal_person_contact: "",
        agreement_done: null,
        agreement_date: null,
        agreement_duration: null,
        branch_images: [],
      };
    },
    getInitialValidationState() {
      return {
        organization_id: false,
        toilet_type: false,
        category: false,
        status: false,
        disabled_status: false,
        service_type: false,
        location: false,
      };
    },
    fetchOrganizations() {
      PublicShauchalaya.getOrganizationNameList()
        .then((response) => {
          // console.log('Fetched organizations:', response.data.data);
          this.organizations = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching organizations:", error);
        });
    },
    handleSelectOrgChange(value) {
      // console.log(`selected ${value}`);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    updateImages(images) {
      this.formState.branch_images = images.map((image) => image.file);
    },
    validateForm() {
      this.validation = {
        organization_id: !this.formState.organization_id,
        toilet_type: !this.formState.toilet_type,
        category: !this.formState.category,
        status: !this.formState.status,
        disabled_status: !this.formState.disabled_status,
        service_type: !this.formState.service_type,
        location: !this.formState.location,
      };
      return !Object.values(this.validation).includes(true);
    },
    dateChange(newDate) {
      this.formState.agreement_date = newDate;
    },
    handleAgreementChange() {
      if (this.formState.agreement_done !== true) {
        this.formState.agreement_date = null;
        this.formState.agreement_duration = null;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      if (!this.validateForm()) {
        return;
      }

      this.isSubmitting = true;
      const formData = new FormData();
      for (let key in this.formState) {
        if (key === "branch_images") {
          this.formState.branch_images.forEach((file) => {
            formData.append("branch_images[]", file);
          });
        } else {
          formData.append(key, this.formState[key]);
        }
      }

      PublicShauchalaya.registerBranch(formData)
        .then((response) => {
          // console.log('Successfully submitted:', response.data);
          this.resetForm();
          this.$emit("refresh");
          message.success("नयाँ शौचालय दर्ता भयो !");
          this.componentKey += 1;
          this.mapComponentKey += 1;
        })
        .catch((error) => {
          message.error("शौचालय दर्तामा समस्या आयोको छ !");
          console.error("Error submitting form:", error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    resetForm() {
      this.formState = this.getInitialFormState();
      this.validation = this.getInitialValidationState();
      if (this.$refs.imageUploader) {
        this.$refs.imageUploader.clearImages();
      }
    },
    showMapModal() {
      this.isMapModalVisible = true;
    },
    handleLocationSelected(location) {
      this.formState.latitude = location[0];
      this.formState.longitude = location[1];
      this.isMapModalVisible = false;

      const accessToken = "c9c7a4c0-2834-4282-94f7-f4c79c40ab0a";
      const apiUrl = `https://route-init.gallimap.com/api/v1/reverse/generalReverse?accessToken=${accessToken}&lat=${location[0]}&lng=${location[1]}`;

      axios
        .get(apiUrl)
        .then((response) => {
          const data = response.data.data;
          this.formState.ward = data.ward;
          this.formState.municipality = data.municipality;
        })
        .catch((error) => {
          console.error("Error fetching location details:", error);
        });
    },
  },
};
</script>

<style scoped>
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.cont {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  gap: 4px;
}

.heading {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  text-align: left;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.subHeading {
  display: flex;
  align-items: center;
}

.headerIcon {
  font-size: x-large;
  margin: 0 12px;
}

.formDiv {
  width: 40%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.form {
  padding: 12px;
}

.tableDiv {
  width: 60%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.table {
  padding: 4px 12px;
}

.ant-input-number-handler-wrap {
  display: none;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .cont {
    flex-direction: column;
  }

  .formDiv,
  .tableDiv {
    width: 100%;
  }
}
</style>

<style>
.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
</style>
