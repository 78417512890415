<template>
    <div class="cont">
        <div v-show="this.$route.name === 'ListOfOrganizations'" class="headerTab">
            <div class="heading"><a-icon style="color: #41cb30;" type="unordered-list" /> संस्था सूची</div>
            <div style="cursor: pointer;" class="newReg heading" type="primary"
                @click="() => $router.push('/organization/register')">
                <a-icon style="color: red;" type="plus-circle" /> नयाँ संस्था दर्ता गर्नुहोस्
            </div>
        </div>
        <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
        <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
        <div v-else class="tableNPagination">
            <a-table class="orgTable" style="width: 100%;" :data-source="data" :columns="filteredColumns"
                :pagination="false">
                <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                    style="padding: 8px">
                    <a-input v-ant-ref="c => (searchInput = c)" :placeholder="`${column.title} खोज्नुहोस्`"
                        :value="selectedKeys[0]" style="width: 188px; margin-bottom: 8px; display: block;"
                        @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                        @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)" />
                    <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px"
                        @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">
                        खोज्नुहोस्
                    </a-button>
                    <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
                        रिसेट
                    </a-button>
                </div>
                <a-icon slot="filterIcon" slot-scope="filtered" type="search"
                    :style="{ color: filtered ? '#108ee9' : undefined }" />
                <template slot="customRender" slot-scope="text, record, index, column">
                    <span class="capitalize" v-if="searchText && searchedColumn === column.dataIndex">
                        <template
                            v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                            <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i"
                                class="highlight">{{
                                    fragment
                                }}</mark>
                            <template v-else>{{ fragment }}</template>
                        </template>
                    </span>
                    <template v-else>
                        <span class="capitalize">{{ text }}</span>
                    </template>
                </template>

                <template slot="nameAndLogo" slot-scope="text, record">
                    <div style="display: flex; align-items: center;">
                        <img :src="record.organization_logo" alt="Logo"
                            style="width: 40px; height: 40px; margin-right: 8px; border-radius: 4px;" />
                        {{ text }}
                    </div>
                </template>

                <template slot="actions" slot-scope="text, record">
                    <a-button title="हेर्नुहोस्" class="actionBtn" style="color: blue;"
                        @click="handleView(record.key)"><a-icon type="eye" /></a-button>
                    <!-- <a-button title="मिलाउनुहोस्" class="actionBtn" style="color: green;"
                        @click="handleEdit(record.key)"><a-icon type="edit" /></a-button> -->
                    <!-- <a-button title="हटाउनुहोस्" class="actionBtn" style="color: red;"
                        @click="handleDelete(record.key)"><a-icon type="delete" /></a-button> -->
                        <a-popconfirm
                        title="के तपाईं पक्का यो संस्था मेट्न चाहनुहुन्छ?"
                        ok-text="हो"
                        cancel-text="होइन"
                        @confirm="handleDelete(record.key)"
                        placement="bottom"
                    >
                        <a-button title="हटाउनुहोस्" class="actionBtn" style="color: red;">
                            <a-icon type="delete" />
                        </a-button>
                    </a-popconfirm>
                </template>

                <template slot="website" slot-scope="text">
                    <a :href="`https://${text}`" target="_blank"
                        style="text-decoration: underline; color: #41cb30; cursor: pointer;">
                        {{ text }}
                    </a>
                </template>
            </a-table>
            <a-pagination :current="currentPage" :total="total" :pageSize="pageSize" @change="handlePageChange" />
        </div>
    </div>
</template>

<script>
import PublicShauchalaya from '../../services/PublicShauchalaya';
import { message } from "ant-design-vue";

export default {
    data() {
        return {
            data: [],
            searchText: '',
            searchInput: null,
            searchedColumn: '',
            currentPage: 1,
            pageSize: 10,
            total: 0,
            loading: true,
            columns: [
                {
                    title: 'S.N.',
                    dataIndex: 'sn',
                    key: 'sn',
                },
                {
                    title: 'संस्थाको नाम',
                    dataIndex: 'organization_name',
                    key: 'organization_name',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'nameAndLogo',
                    },
                    onFilter: (value, record) => {
                        const field = record.organization_name;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'संस्थाको ठेगाना',
                    dataIndex: 'location',
                    key: 'location',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value, record) => {
                        const field = record.location;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'शौचालयको संख्या',
                    dataIndex: 'total_toilets',
                    key: 'total_toilets',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value, record) => {
                        const field = record.total_toilets;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'संस्थाको इमेल',
                    dataIndex: 'organization_email',
                    key: 'organization_email',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value, record) => {
                        const field = record.organization_email;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'संस्थाको फोन नम्बर',
                    dataIndex: 'organization_contact',
                    key: 'organization_contact',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value, record) => {
                        const field = record.organization_contact;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'संस्थाको वेबसाइट',
                    dataIndex: 'organization_website_url',
                    key: 'organization_website_url',
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'website',
                    },
                    onFilter: (value, record) => {
                        const field = record.organization_website_url;
                        return field && field.toString().toLowerCase().includes(value.toLowerCase());
                    },
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            }, 0);
                        }
                    },
                },
                {
                    title: 'कार्यहरू',
                    key: 'actions',
                    scopedSlots: { customRender: 'actions' },
                },
            ],
        };
    },
    created() {
        this.fetchData();
        this.$parent.$on('refresh', this.fetchData);
    },
    beforeDestroy() {
        this.$parent.$off('refresh', this.fetchData);
    },
    mounted() {
        this.fetchData();
    },
    computed: {
        filteredColumns() {
            if (this.$route.name === 'PublicShauchalayaOrganizationRegister') {
                return this.columns.filter(column => !['organization_email', 'organization_contact', 'organization_website_url'].includes(column.dataIndex));
            }
            return this.columns;
        }
    },
    methods: {
        fetchData(page = 1) {
            PublicShauchalaya.getOrganizationList(page)
                .then(response => {
                    const organizations = response.data.data;
                    const formattedData = organizations.map((organization, index) => ({
                        key: organization.id,
                        sn: (page - 1) * this.pageSize + index + 1,
                        organization_name: organization.organization_name,
                        organization_logo: organization.organization_logo,
                        location: organization.organization_address,
                        organization_type: organization.organization_type,
                        total_toilets: organization.branches.length,
                        organization_email: organization.organization_email,
                        organization_contact: organization.organization_contact,
                        organization_website_url: organization.organization_website_url,
                    }));
                    this.data = formattedData;
                    this.total = response.data.meta.total;
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchData(page);
        },
        handleSearch(selectedKeys, confirm, dataIndex) {
            confirm();
            this.searchText = selectedKeys[0];
            this.searchedColumn = dataIndex;
        },
        handleReset(clearFilters) {
            clearFilters();
            this.searchText = '';
        },
        handleView(id) {
            this.$router.push(`/organization/${id}`)
            console.log('Viewed branch with id:', id)
        },
        handleDelete(id) {
            // if (confirm('तपाईं पक्का यो संस्था मेट्न चाहनुहुन्छ?')) {
            PublicShauchalaya.deleteOrganization(id)
                .then(response => {
                    this.fetchData();
                    // console.log('Deleted:', response.data.message)
                    message.success("संस्था सफलतापूर्वक मेटिएको छ!");
                })
                .catch(error => {
                    message.error("संस्था मेटाउन समस्या आएको छ!");
                    console.error('Error deleting branch:', error);
                });
            // }
        },
        handleEdit(id) {
            // console.log('Editted')
        },
    },
};
</script>

<style scoped>
.highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
}

.cont {
    border: 1px solid rgb(210, 210, 210);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.headerTab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(210, 210, 210);
    padding: 4px 12px;
}

.heading {
    color: #2d67bd;
    font-size: 16px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    gap: 4px;
}

.loader {
  padding: 20px;
}

.actionBtn {
    background-color: transparent;
    border: none;
    font-size: larger;
    box-shadow: none;
    margin: 0;
}

.tableNPagination {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: hidden;
}
</style>