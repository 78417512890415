<template>
    <div class="cont">
        <h1>संस्था एडिट गर्नुहोस्</h1>
        <a-form class="form" @submit="handleSubmit" layout="vertical">
            <a-row :gutter="[8, 8]">
                <a-col :span="12">
                    <a-form-item label="संस्थाको नाम">
                        <a-input v-model="formState.organization_name" type="text" />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="संस्थाको ठेगाना">
                        <a-input v-model="formState.organization_address" type="text" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[8, 8]">
                <a-col :span="12">
                    <a-form-item label="संस्थाको प्रकार">
                        <a-select v-model="formState.organization_type">
                            <a-select-option value="private">निजी</a-select-option>
                            <a-select-option value="public">सार्वजनिक</a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="संस्थाको फोन नम्बर">
                        <a-input v-model="formState.organization_contact" type="tel" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[8, 8]">
                <a-col :span="12">
                    <a-form-item label="संस्थाको इमेल">
                        <a-input v-model="formState.organization_email" type="email" />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="संस्थाको सम्पर्क व्यक्ति">
                        <a-input v-model="formState.focal_person" type="text" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[8, 8]">
                <a-col :span="12">
                    <a-form-item label="संस्थाको वेबसाइट">
                        <a-input v-model="formState.organization_website_url" type="text" />
                    </a-form-item>
                </a-col>
                <a-col :span="12">
                    <a-form-item label="संस्थाको लोगो">
                        <a-input ref="logoInput" @change="handleLogoUpload" type="file" accept="image/*"
                            style="padding: 0px 4px;" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="[8, 8]">
                <a-col :span="24">
                    <a-form-item label="संस्थाको विवरण">
                        <a-textarea v-model="formState.organization_description" placeholder="संस्थाको विवरण दिनुहोस्"
                            :rows="4" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-form-item>
                <a-button type="primary" html-type="submit" :loading="isSubmitting">
                    सेभ गर्नुहोस्
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { Form, Row, Col, Input, Select, Button } from 'ant-design-vue';
import PublicShauchalaya from '../../services/PublicShauchalaya';
import { message } from "ant-design-vue";

export default {
    components: {
        'a-form': Form,
        'a-row': Row,
        'a-col': Col,
        'a-input': Input,
        'a-select': Select,
        'a-select-option': Select.Option,
        'a-button': Button,
    },
    props: ['id'],
    data() {
        return {
            formState: {
                organization_name: '',
                organization_address: '',
                organization_type: '',
                organization_contact: '',
                organization_email: '',
                focal_person: '',
                organization_website_url: '',
                organization_description: '',
                organization_logo: null,
            },
            isSubmitting: false,
        };
    },
    mounted() {
        this.fetchOrganizationDetails();
    },
    methods: {
        fetchOrganizationDetails() {
            PublicShauchalaya.getIndividualOrganization(this.id)
                .then(response => {
                    const org = response.data.data;
                    this.formState = {
                        organization_name: org.organization_name,
                        organization_address: org.organization_address,
                        organization_type: org.organization_type,
                        organization_contact: org.organization_contact,
                        organization_email: org.organization_email,
                        focal_person: org.focal_person,
                        organization_website_url: org.organization_website_url,
                        organization_description: org.organization_description,
                        organization_logo: null,
                    };
                })
                .catch(error => {
                    console.error('Error fetching organization details:', error);
                });
        },
        handleLogoUpload(e) {
            const logo = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                this.formState.organization_logo = { file: logo, url: event.target.result };
            };
            reader.readAsDataURL(logo);
        },
        handleSubmit(e) {
            e.preventDefault();
            this.isSubmitting = true;
            const formData = new FormData();
            formData.append('id', this.id);
            formData.append('organization_name', this.formState.organization_name);
            formData.append('organization_type', this.formState.organization_type);
            formData.append('organization_description', this.formState.organization_description);
            formData.append('focal_person', this.formState.focal_person);
            formData.append('organization_address', this.formState.organization_address);
            formData.append('organization_contact', this.formState.organization_contact);
            formData.append('organization_email', this.formState.organization_email);
            formData.append('organization_website_url', PublicShauchalaya.ensureHttps(this.formState.organization_website_url));

            if (this.formState.organization_logo) {
                formData.append('organization_logo', this.formState.organization_logo.file);
            }

            PublicShauchalaya.updateOrganization(formData)
                .then(response => {
                    message.success("संस्था अपडेट भयो !");
                    this.$router.push(`/organization/${this.id}`);
                })
                .catch(error => {
                    message.error("संस्था अपडेटमा समस्या आयोको छ !");
                    console.error('Error updating organization:', error);
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
    },
};
</script>

<style scoped>
.cont {
    width: 100%;
    padding: 20px;
    border: 1px solid #dedede;
    border-radius: 8px;
}

.form {
    padding: 12px;
}

.datepicker {
    padding: 4px;
    height: 30px;
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.datepicker>input {
    border: none !important;
}

.nepalidatepick {
    font-variant: tabular-nums;
    font-feature-settings: "tnum";
    font-size: 14px;
    background-color: transparent;
    border: none !important;
}
</style>