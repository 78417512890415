<template>
  <div class="image-uploader">
    <input
      class="inputField"
      type="file"
      multiple
      @change="onFileChange"
      accept="image/*"
    />
    <div class="previews">
      <div v-for="(image, index) in images" :key="index" class="preview">
        <img :src="image.url" :alt="'Image ' + (index + 1)" />
        <div class="image-info">
          <p>{{ truncateName(image.file.name) }}</p>
          <p>{{ formatSize(image.file.size) }}</p>
        </div>
        <button type="button" @click="removeImage(index)">
          <a-icon type="delete" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [],
    };
  },
  methods: {
    onFileChange(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images.push({ file: file, url: e.target.result });
          this.$emit("update-images", this.images);
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
      this.$emit("update-images", this.images);
    },
    clearImages() {
      this.images = [];
      this.$emit("update-images", this.images);
    },
    formatSize(size) {
      const units = ["B", "KB", "MB", "GB"];
      let unitIndex = 0;
      let formattedSize = size;
      while (formattedSize >= 1024 && unitIndex < units.length - 1) {
        formattedSize /= 1024;
        unitIndex++;
      }
      return `${formattedSize.toFixed(2)} ${units[unitIndex]}`;
    },
    truncateName(name) {
      const maxLength = 15;
      if (name.length <= maxLength) {
        return name;
      }
      const extIndex = name.lastIndexOf(".");
      const ext = extIndex !== -1 ? name.substring(extIndex) : "";
      const truncatedName =
        name.substring(0, maxLength - ext.length - 3) + "..." + ext;
      return truncatedName;
    },
  },
};
</script>

<style scoped>
.image-uploader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

.inputField {
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  width: 100%;
  padding: 2px 4px;
}

.previews {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}

.preview {
  display: flex;
  width: 200px;
  margin-right: 2px;
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;
  border: 2px solid rgb(206, 206, 206);
  border-radius: 8px;
}

.preview img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.preview button {
  background-color: transparent;
  color: red;
  border: none;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
