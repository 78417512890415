<template>
  <div class="feedback-cont">
    <h1 style="margin: 8px 0px;">प्रतिक्रिया सूची</h1>
    <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
    <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
    <div v-else class="feedbacks-list">
      <div v-for="feedback in feedbacks" :key="feedback.id" class="feedback-card">
        <div class="feedback-header">
          <div class="organization-info">
            <p style="font-size: small;">
              <span style="font-weight: bold;">शौचालय ठेगाना: </span>{{ feedback.branch.location }}
            </p>
            <p style="font-size: small;">
              <span style="font-weight: bold;">व्यवस्थापक संस्था: </span>
              <img :src="feedback.branch.organization_image" alt="logo" class="organization-logo" />
              {{ feedback.branch.organization_name }}
            </p>
          </div>
        </div>
        <h5><span style="font-weight: bold; margin-right: 4px;">शीर्षक:</span> {{ feedback.name }}</h5>
        <div style="margin: 4px 0px;">
          <h5 style="font-weight: bold;">प्रतिक्रिया:</h5>
          <p>{{ feedback.description }}</p>
        </div>
        <div v-if="feedback.feedback_images && feedback.feedback_images.length" class="feedback-images">
          <img v-for="image in feedback.feedback_images" :key="image.id" :src="image.feedback_image"
            alt="feedback image" class="feedback-image" @click="openImageInNewTab(image.feedback_image)" />
        </div>
      </div>
    </div>
    <a-pagination :current="currentPage" :pageSize="pageSize" :total="totalFeedbacks" @change="handlePageChange"
      style="text-align: center; margin-top: 20px;" />
  </div>
</template>

<script>
import { Rate, Pagination } from 'ant-design-vue';
import PublicShauchalaya from '../../services/PublicShauchalaya';

export default {
  components: {
    'a-rate': Rate,
    'a-pagination': Pagination,
  },
  data() {
    return {
      feedbacks: [],
      currentPage: 1,
      pageSize: 10,
      totalFeedbacks: 0,
      loading: true,
    };
  },
  mounted() {
    this.getFeedbacks();
  },
  methods: {
    getFeedbacks(page = 1) {
      PublicShauchalaya.getAllFeedbacksList(page)
        .then(response => {
          this.feedbacks = response.data.data;
          this.totalFeedbacks = response.data.meta.total;
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getFeedbacks(page);
    },
    openImageInNewTab(url) {
      window.open(url, '_blank');
    }
  }
};
</script>


<style scoped>
.feedback-cont {
  width: 100%;
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 8px;
}

.feedbacks-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.feedback-card {
  background: white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  width: 49.5%;
  min-height: 150px;
}

.feedback-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
}

.organization-logo {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}

.organization-info {
  display: flex;
  flex-direction: column;
}

.feedback-images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.feedback-image {
  width: 100px;
  height: 100px;
  margin-right: 5px;
  margin-bottom: 5px;
  object-fit: cover;
  cursor: pointer;
}

.feedback-item p {
  margin: 0;
}

.loader{
    display: flex;
    justify-content: center;
    padding: 20px;
}

@media (max-width: 800px) {
  .feedback-card {
    width: 99%;
  }
}
</style>
