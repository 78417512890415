var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'ListOfToilets'),expression:"this.$route.name === 'ListOfToilets'"}],staticClass:"headerTab"},[_c('div',{staticClass:"heading"},[_c('a-icon',{staticStyle:{"color":"#41cb30"},attrs:{"type":"unordered-list"}}),_vm._v(" शौचालय सूची ")],1),_c('div',{staticClass:"newReg heading",staticStyle:{"cursor":"pointer"},attrs:{"type":"primary"},on:{"click":() => _vm.$router.push('/branch/register')}},[_c('a-icon',{staticStyle:{"color":"red"},attrs:{"type":"plus-circle"}}),_vm._v(" नयाँ शौचालय दर्ता गर्नुहोस् ")],1)]),(_vm.loading)?_c('a-spin',{staticClass:"loader",attrs:{"spinning":_vm.loading,"tip":"लोड हुँदैछ..."}}):_c('div',{staticClass:"tableNPagination"},[_c('a-table',{staticStyle:{"width":"100%"},attrs:{"data-source":_vm.data,"columns":_vm.filteredColumns,"pagination":false},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`${column.title} खोज्नुहोस्`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" खोज्नुहोस् ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" रिसेट ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',{staticClass:"capitalize"},[_vm._l((text
              .toString()
              .split(new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i'))),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(text))])]]}},{key:"actions",fn:function(text, record){return [_c('a-button',{staticClass:"actionBtn",staticStyle:{"color":"blue"},attrs:{"title":"हेर्नुहोस्"},on:{"click":function($event){return _vm.handleView(record.key)}}},[_c('a-icon',{attrs:{"type":"eye"}})],1),_c('a-popconfirm',{attrs:{"title":"के तपाईं पक्का यो शौचालय मेट्न चाहनुहुन्छ?","ok-text":"हो","cancel-text":"होइन","placement":"bottom"},on:{"confirm":function($event){return _vm.handleDelete(record.key)}}},[_c('a-button',{staticClass:"actionBtn",staticStyle:{"color":"red"},attrs:{"title":"हटाउनुहोस्"}},[_c('a-icon',{attrs:{"type":"delete"}})],1)],1)]}}])}),_c('a-pagination',{attrs:{"current":_vm.currentPage,"total":_vm.total,"pageSize":_vm.pageSize},on:{"change":_vm.handlePageChange}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }