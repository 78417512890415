<template>
    <div class="cont">
        <div class="formDiv" :key="componentKey">
            <div style="justify-content: start;" class="heading">
                <a-icon style="color: #3B5CA5;" class="headerIcon" type="form" /> नयाँ दर्ता
            </div>
            <a-form class="form" @submit="handleSubmit" layout="vertical">
                <a-row :gutter="[8, 8]">
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको नाम"
                            :validate-status="validation.name ? 'error' : ''"
                            :help="validation.name ? 'Field is required' : ''">
                            <a-input v-model="formState.name" type="text" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको ठेगाना"
                            :validate-status="validation.address ? 'error' : ''"
                            :help="validation.address ? 'Field is required' : ''">
                            <a-input v-model="formState.address" type="text" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 8]">
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको प्रकार"
                            :validate-status="validation.type ? 'error' : ''"
                            :help="validation.type ? 'Field is required' : ''">
                            <a-select v-model="formState.type">
                                <a-select-option value="private">निजी</a-select-option>
                                <a-select-option value="public">सार्वजनिक</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको फोन नम्बर">
                            <a-input v-model="formState.number" type="tel" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 8]">
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको इमेल">
                            <a-input v-model="formState.email" type="email" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको सम्पर्क व्यक्ति"
                            :validate-status="validation.focalPerson ? 'error' : ''"
                            :help="validation.focalPerson ? 'Field is required' : ''">
                            <a-input v-model="formState.focalPerson" type="text" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 8]">
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको वेबसाइट">
                            <a-input v-model="formState.website" type="text" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको लोगो"
                            :validate-status="validation.logo ? 'error' : ''"
                            :help="validation.logo ? 'Field is required' : ''">
                            <a-input ref="logoInput" @change="handleLogoUpload" type="file" accept="image/*"
                                style="padding: 0px 4px;" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 8]">
                    <a-col :span="24">
                        <a-form-item v-bind="formItemLayout" label="संस्थाको विवरण"
                            :validate-status="validation.description ? 'error' : ''"
                            :help="validation.description ? 'Field is required' : ''">
                            <a-textarea v-model="formState.description" placeholder="संस्थाको विवरण दिनुहोस्"
                                :rows="4" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-form-item>
                    <a-button style="background-color: #2c68aa;" type="primary" html-type="submit"
                        :loading="isSubmitting">
                        दर्ता गर्नुहोस्
                    </a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="tableDiv">
            <div class="heading">
                <div class="subHeading">
                    <a-icon style="color: #3B5CA5;" class="headerIcon" type="unordered-list" /> संस्थाहरूको सूची
                </div>
                <div style="cursor: pointer;" @click="() => $router.push('/organization/list')"
                    class="subHeading">
                    <a-icon style="color: red;" class="headerIcon" type="eye" /> पूर्ण सूची हेर्नुहोस्
                </div>
            </div>
            <div class="table">
                <ListOfOrganizations />
            </div>
        </div>
    </div>
</template>

<script>
import PublicShauchalaya from '../../services/PublicShauchalaya';
import ListOfOrganizations from './ListOfOrganizations.vue';
import { message } from "ant-design-vue";

export default {
    data() {
        return {
            formState: this.getInitialFormState(),
            formItemLayout: {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 24 },
                },
            },
            validation: this.getInitialValidationState(),
            componentKey: 0,
            isSubmitting: false,
        };
    },
    components: {
        ListOfOrganizations,
    },
    methods: {
        getInitialFormState() {
            return {
                name: '',
                address: '',
                type: '',
                number: '',
                email: '',
                focalPerson: '',
                website: '',
                logo: null,
                description: '',
            };
        },
        getInitialValidationState() {
            return {
                name: false,
                address: false,
                type: false,
                focalPerson: false,
                logo: false,
                description: false,
                numberError: '',
            };
        },
        handleLogoUpload(e) {
            const logo = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                this.formState.logo = { file: logo, url: event.target.result };
            };
            reader.readAsDataURL(logo);
        },
        validateForm() {
            this.validation = {
                name: !this.formState.name,
                address: !this.formState.address,
                type: !this.formState.type,
                focalPerson: !this.formState.focalPerson,
                logo: !this.formState.logo,
                description: !this.formState.description,
                numberError: !/^\d+$/.test(this.formState.number) ? 'Only numbers are allowed' : '',
            };
            return !Object.values(this.validation).includes(true);
        },
        handleSubmit(e) {
            e.preventDefault();
            if (!this.validateForm()) {
                return;
            }
            this.isSubmitting = true;
            const formData = new FormData();
            formData.append('organization_name', this.formState.name);
            formData.append('organization_type', this.formState.type);
            formData.append('organization_description', this.formState.description);
            formData.append('focal_person', this.formState.focalPerson);
            formData.append('organization_address', this.formState.address);
            formData.append('organization_contact', this.formState.number);
            formData.append('organization_email', this.formState.email);
            formData.append('organization_website_url', PublicShauchalaya.ensureHttps(this.formState.website));

            if (this.formState.logo) {
                formData.append('organization_logo', this.formState.logo.file);
            }

            PublicShauchalaya.registerOrganization(formData)
                .then(response => {
                    this.resetForm();
                    this.$emit('refresh');
                    this.componentKey += 1;
                    message.success("नयाँ संस्था दर्ता भयो !");
                })
                .catch(error => {
                    message.error("संस्था दर्तामा समस्या आयोको छ !");
                    console.error('Error submitting form:', error);
                })
                .finally(() => {
                    this.isSubmitting = false;
                });
        },
        resetForm() {
            this.formState = this.getInitialFormState();
            this.validation = this.getInitialValidationState();
            this.$refs.logoInput.value = '';
        },
    },
};
</script>

<style scoped>
.cont {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    gap: 4px;
}

.heading {
    width: 100%;
    border-bottom: 1px solid #d9d9d9;
    text-align: left;
    font-size: larger;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
}

.subHeading {
    display: flex;
    align-items: center;
}

.headerIcon {
    font-size: x-large;
    margin: 0 12px;
}

.formDiv {
    width: 40%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.form {
    padding: 12px;
}

.tableDiv {
    width: 60%;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
}

.table {
    padding: 4px 12px;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
    .cont {
        flex-direction: column;
    }

    .formDiv,
    .tableDiv {
        width: 100%;
    }
}
</style>
