<template>
  <div> 
    <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
    <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
  <div v-else  class="verification-container">
    <div class="left-verification-section">
      <h3 class="section-title">प्रमाणित हुन बाँकी संस्थाहरू</h3>
      <a-table class="verification-table" :columns="columns" :dataSource="unverifiredOrgs" :pagination="false"
        rowKey="id">
        <template slot="org_name" slot-scope="text, record">
          <div class="organization-info">
            <img :src="record.organization_image" alt="logo" class="organization-logo" />
            <span>{{ record.organization_name }}</span>
          </div>
        </template>
        <template slot="verification_pdf" slot-scope="text, record">
          <a class="pdf-link" :href="record.verification_pdf" target="_blank">
            <a-icon class="pdf-icon" type="file-pdf" />
          </a>
        </template>
        <template slot="action" slot-scope="text, record">
          <div class="action-buttons">
            <a-button class="verify-button" title="स्वीकृत" @click="verifyOrganization(record.id)">
              <a-icon class="action-icon" type="check" />
            </a-button>
            <!-- <a-button class="unverify-button" title="अस्वीकार" @click="unverifyOrganization(record.id)">
              <a-icon class="action-icon" type="close" />
            </a-button> -->
          </div>
        </template>
      </a-table>
    </div>
    <div class="right-verification-section">
      <h3 class="section-title">हालै प्रमाणित भएका संस्थाहरू</h3>
      <a-table class="verification-table" :columns="verifiedOrgsColumns" :dataSource="verifiedOrgs" :pagination="false"
        rowKey="id">
        <template slot="verfied_org_name" slot-scope="text, record">
          <div class="organization-info">
            <img :src="record.organization_image" alt="logo" class="organization-logo" />
            <span>{{ record.organization_name }}</span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</div>
</template>


<script>
import PublicShauchalaya from '../../services/PublicShauchalaya';
import { message } from 'ant-design-vue';
export default {
  data() {
    return {
      loading: true,
      unverifiredOrgs: [],
      verifiedOrgs: [],
      verifiedOrgsColumns: [
        {
          title: 'S.N.',
          key: 'sn',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: 'संस्थाको नाम',
          key: 'organization_name',
          scopedSlots: { customRender: 'verfied_org_name' },
        },
      ],
      columns: [
        {
          title: 'S.N.',
          key: 'sn',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: 'संस्थाको नाम',
          key: 'organization_name',
          scopedSlots: { customRender: 'org_name' },
        },
        {
          title: 'ठेगाना',
          dataIndex: 'organization_address',
          key: 'organization_address',
        },
        {
          title: 'केन्द्रिय व्यक्ति',
          dataIndex: 'focal_person',
          key: 'focal_person',
        },
        {
          title: 'सम्पर्क नम्बर',
          dataIndex: 'organization_contact',
          key: 'organization_contact',
        },
        {
          title: 'PDF',
          dataIndex: 'verification_pdf',
          key: 'verification_pdf',
          scopedSlots: { customRender: 'verification_pdf' },
        },
        {
          title: 'कार्यहरू',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  mounted() {
    this.getUnverifiedOrgs();
    this.getVerifiedOrgs();
  },
  methods: {
    getUnverifiedOrgs() {
      PublicShauchalaya.getUnverifiedOrganizationList()
        .then(response => {
          this.unverifiredOrgs = response.data.data.map((org, index) => ({ ...org, sn: index + 1 }));
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          this.loading = false;
        });
    },
    getVerifiedOrgs() {
      PublicShauchalaya.getVerifiedOrganizatonList()
        .then(response => {
          this.verifiedOrgs = response.data.data;
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          this.loading = false;
        });
    },
    verifyOrganization(id) {
      PublicShauchalaya.verifyOrganization(id)
        .then(response => {
          this.getUnverifiedOrgs();
          this.getVerifiedOrgs();
          message.success('संस्था सफलतापूर्वक प्रमाणित भएको छ।');
        })
        .catch(error => {
          console.log(error);
          message.error('संस्थाको प्रमाणिकरणमा समस्या आएको छ।');
        });
    },
    unverifyOrganization(id) {
      PublicShauchalaya.unverifyOrganization(id)
        .then(response => {
          this.getUnverifiedOrgs();
          this.getVerifiedOrgs();
          message.info("संस्थाको प्रमाणिकरण सफलतापूर्वक रद्द गरिएको छ।");
        })
        .catch(error => {
          console.log(error);
          message.error('संस्थाको प्रमाणिकरण रद्दमा समस्या आएको छ। कृपया पुनः प्रयास गर्नुहोस्।');
        });
    },
  },
}
</script>



<style scoped>
.verification-container {
  width: 100%;
  display: flex;
  gap: 10px;
}

.left-verification-section {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px;
  width: 70%;
}

.right-verification-section {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 12px;
  width: 30%;
}

.section-title {
  margin: 0 0 12px;
  font-size: 16px;
  color: #333;
}

.verification-table {
  border: 1px solid #dedede;
  border-radius: 8px;
  min-height: 300px;
}

.organization-info {
  display: flex;
  align-items: center;
}

.organization-logo {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.pdf-link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdf-icon {
  color: blue;
  font-size: 20px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.verify-button,
.unverify-button {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-icon {
  font-size: 17px;
}

.verify-button .action-icon {
  color: green;
}

.unverify-button .action-icon {
  color: red;
}

.loader{
    display: flex;
    justify-content: center;
    padding: 20px;
}

@media (max-width: 1000px) {
  .verification-container {
    flex-direction: column;
  }

  .left-verification-section,
  .right-verification-section {
    width: 100%;
  }
}
</style>
