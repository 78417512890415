<template>
    <div class="cont">
        <a-card class="login-card">
            <div class="login-header">
                <img class="formLogo" src="../assets/logo.jpg" alt="">
                <h2>सार्वजनिक शौचालय प्रबन्धन</h2>
            </div>
            <a-form @submit="handleSubmit" class="login-form">
                <div class="flexFormItem">
                    <a-icon type="mail" />
                    <input class="formInput" v-model="email" placeholder="इमेल" prefix-icon="mail" />
                </div>
                <div class="flexFormItem">
                    <a-icon type="lock" />
                    <input :type="showPassword ? 'text' : 'password'" class="formInput" v-model="password"
                        placeholder="पासवर्ड" prefix-icon="lock" />
                    <a-icon v-if="password" :type="showPassword ? 'eye-invisible' : 'eye'"
                        @click="togglePasswordVisibility" class="togglePasswordIcon" />
                </div>
                <a-button :loading="isLoading" type="primary" html-type="submit" class="login-button custom-button">लग
                    इन गर्नुहोस्</a-button>
            </a-form>
        </a-card>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import Auth from '@/services/Auth';
import { message } from "ant-design-vue";

export default {
    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            isLoading: false
        };
    },
    methods: {
        async handleSubmit(e) {
            e.preventDefault();
            this.isLoading = true;

            const formData = new FormData();
            formData.append('email', this.email);
            formData.append('password', this.password);


            try {
                const res = await Auth.handleLogin(formData);
                const token = res.data.data.token;
                Cookies.set('toilet_token', token, { expires: 7 });
                await this.$router.push('/');
                message.success("लगइन सफल भयो।");
            } catch (error) {
                console.error('Login Error:', error);
                message.error("लग इनमा समस्या आयोको छ !");
            } finally {
                this.isLoading = false;
            }
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        }
    }
};
</script>

<style scoped>
.cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background: linear-gradient(90deg, rgb(1, 201, 114) 0%, #48d358 30%, rgb(13, 156, 94) 100%); */
    /* background: linear-gradient(90deg, #1D976C, #67be87); */
    background: linear-gradient(90deg, #475ba0, #ca586e);

}

.login-card {
    width: 360px;
    height: 400px;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2{
    margin: 8px 0px;
}

.formLogo {
    height: 120px;
    width: 120px;
    pointer-events: none;
}

.flexFormItem {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 2px 8px;
}

.formInput {
    border: none;
    height: 40px;
    width: 220px;
}

.formInput:focus {
    border: none;
    outline: none;
    box-shadow: none;
}

.togglePasswordIcon {
    cursor: pointer;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.25);
}

.login-header {
    text-align: center;
    margin-bottom: 24px;
}

.login-icon {
    font-size: 32px;
}

.login-form {
    gap: 0px;
}

.login-form .login-button {
    width: 100%;
}

.login-button {
    background-color: #053b74;
    border: none;
    font-size: 16px;
    height: 40px;
    transition: background-color 0.3s;
}

.login-button:hover,
.login-button:focus,
.login-button:active {
    background-color: #013d7d;
    border: none;
}

::placeholder {
    font-size: 16px;
}

/* Custom styles for the button */
.custom-button {
    background-color: #053b74;
    border-color: #013d7d;
}

.custom-button:hover,
.custom-button:focus,
.custom-button:active {
    background-color: #013d7d !important;
    border-color: #013d7d !important;
}
</style>
