<template>
    <div class="dashboardPage">
        <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
        <div v-else>
            <div class="upperDetails">
                <div class="infoCards">
                    <div class="orgCards">
                        <h4 style="margin: 8px 0px;">संस्थाहरूको सांख्यिकी</h4>
                        <div class="cards">
                            <div v-for="data in organizationsData" :key="data.label" class="singleCard"
                                @click="updateChart(data)">
                                <div :class="{ 'selected-card': selectedCard === data.label }" class="cardIcon">
                                    <p class="cardCount">{{ convertToNepaliNumerals(data.count) }}</p>
                                </div>
                                <p class="cardLabel">{{ data.label }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="branchCards">
                        <h4 style="margin: 8px 0px;">शौचालयहरूको सांख्यिकी</h4>
                        <div class="cards">
                            <div v-for="data in branchesData" :key="data.label" class="singleCard"
                                @click="updateChart(data)">
                                <div class="cardIconContainer">
                                    <div :class="{ 'selected-card': selectedCard === data.label }" class="cardIcon">
                                        <p class="cardCount">{{ convertToNepaliNumerals(data.count) }}</p>
                                    </div>
                                </div>
                                <p class="cardLabel">{{ data.label }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="infoTable">
                    <div class="indivCharDiv">
                        <h4>पाई चार्ट</h4>
                        <PieChart :series="chartSeries" :labels="chartLabels" ref="pieChart" />
                    </div>
                    <div class="indivCharDiv">
                        <h4>बार चार्ट</h4>
                        <BarChart :series="[{ data: chartSeries }]" :labels="chartLabels" ref="barChart" />
                    </div>
                </div>
            </div>
            <div class="lowerDetails">
                <h4 style="margin: 8px 0px; font-size: larger;">नक्सामा सबै शौचालयहरू</h4>
                <div id="displayMap" style="height: 600px; width: 100%; border-radius: 10px;"></div>
                <div id="threedimension"></div>
            </div>
        </div>
    </div>
</template>

<script>
import PublicShauchalaya from '../../services/PublicShauchalaya';
import PieChart from '../../components/Dashboard/PieChart.vue';
import BarChart from '../../components/Dashboard/BarChart.vue';
import KMC_Ward_Boundary from '../../services/KMC_Ward_Boundary.json';

export default {
    components: {
        PieChart,
        BarChart
    },
    data() {
        return {
            loading: true,
            selectedCard: null,
            dashboardData: null,
            organizationsData: [],
            branchesData: [],
            chartSeries: [],
            chartLabels: [],
            branchGeolocations: [],
            wardBoundaries: KMC_Ward_Boundary.features 
        };
    },
    mounted() {
        this.getDashboardData();
    },
    methods: {
        getDashboardData() {
            PublicShauchalaya.getDashboardData()
                .then(response => {
                    this.dashboardData = response.data.data;
                    this.populateData();
                    this.setDefaultChart();
                    this.getBranchGeolocations(); 
                })
                .catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false;
                });
        },
        populateData() {
            const data = this.dashboardData || {};
            this.organizationsData = [
                {
                    label: 'कुल संस्थाहरू',
                    count: data.organizations || 0,
                },
                {
                    label: 'कुल निजी संस्थाहरू',
                    count: data.org_private_count || 0,
                },
                {
                    label: 'कुल सार्वजनिक संस्थाहरू',
                    count: data.org_public_count || 0,
                },
            ];
            this.branchesData = [
                {
                    label: 'कुल शौचालय',
                    count: data.branches || 0,
                },
                {
                    label: 'कुल निःशुल्क शौचालय मात्र',
                    count: data.branch_free_count || 0,
                },
                {
                    label: 'कुल सशुल्क शौचालय मात्र',
                    count: data.branch_paid_count || 0,
                },
                {
                    label: 'कुल निजी शौचालय मात्र',
                    count: data.branch_private_count || 0,
                },
                {
                    label: 'कुल सार्वजनिक शौचालय मात्र',
                    count: data.branch_public_count || 0,
                },
                {
                    label: 'कुल पुरुष शौचालय मात्र',
                    count: data.branch_male_count || 0,
                },
                {
                    label: 'कुल महिला शौचालय मात्र',
                    count: data.branch_female_count || 0,
                },
                {
                    label: 'कुल सबै शौचालय',
                    count: data.branch_all_count || 0,
                },
                {
                    label: 'कुल सक्रिय शौचालय मात्र',
                    count: data.branch_active_count || 0,
                },
                {
                    label: 'कुल निष्क्रिय शौचालय मात्र',
                    count: (data.branches - data.branch_active_count) || 0,
                },
                {
                    label: 'कुल अपांगमैत्री शौचालय',
                    count: data.branch_disabled_count || 0,
                },
            ];
        },
        setDefaultChart() {
            this.chartLabels = ['पुरुष', 'महिला', 'सबै'];
            this.chartSeries = [
                this.dashboardData ? this.dashboardData.branch_male_count : 0,
                this.dashboardData ? this.dashboardData.branch_female_count : 0,
                this.dashboardData ? this.dashboardData.branch_all_count : 0
            ];
        },
        updateChart(data) {
            this.selectedCard = data.label;
            if (data.label.includes('संस्थाहरू')) {
                this.chartLabels = ['निजी संस्थाहरू', 'सार्वजनिक संस्थाहरू'];
                this.chartSeries = [
                    this.dashboardData ? this.dashboardData.org_private_count : 0,
                    this.dashboardData ? this.dashboardData.org_public_count : 0
                ];
            } else if (data.label.includes('शौचालय')) {
                if (data.label.includes('निःशुल्क')) {
                    this.chartLabels = ['निःशुल्क', 'श:शुल्क'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_free_count : 0,
                        this.dashboardData ? this.dashboardData.branch_paid_count : 0
                    ];
                } else if (data.label.includes('सक्रिय') || data.label.includes('निष्क्रिय')) {
                    this.chartLabels = ['सक्रिय', 'निष्क्रिय'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_active_count : 0,
                        this.dashboardData ? (this.dashboardData.branches - this.dashboardData.branch_active_count) : 0
                    ];
                } else if (data.label.includes('निजी') || data.label.includes('सार्वजनिक')) {
                    this.chartLabels = ['निजी', 'सार्वजनिक'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_private_count : 0,
                        this.dashboardData ? this.dashboardData.branch_public_count : 0
                    ];
                } else if (data.label.includes('पुरुष') || data.label.includes('महिला') || data.label.includes('सबै')) {
                    this.chartLabels = ['पुरुष', 'महिला', 'सबै'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_male_count : 0,
                        this.dashboardData ? this.dashboardData.branch_female_count : 0,
                        this.dashboardData ? this.dashboardData.branch_all_count : 0
                    ];
                } else if (data.label.includes('अपांगमैत्री')) {
                    this.chartLabels = ['अपांगमैत्री', 'अन्य'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_disabled_count : 0,
                        this.dashboardData ? (this.dashboardData.branches - this.dashboardData.branch_disabled_count) : 0
                    ];
                } else {
                    this.chartLabels = ['निःशुल्क', 'श:शुल्क'];
                    this.chartSeries = [
                        this.dashboardData ? this.dashboardData.branch_free_count : 0,
                        this.dashboardData ? this.dashboardData.branch_paid_count : 0
                    ];
                }
            }
        },
        convertToNepaliNumerals(number) {
            return PublicShauchalaya.convertToNepaliNumerals(number);
        },
        getBranchGeolocations() {
            PublicShauchalaya.getAllBranchGeolocations()
                .then(response => {
                    this.branchGeolocations = response.data.data;
                    if (!this.loading) {
                        this.initializeMap();
                    }
                })
                .catch(error => {
                    console.error('Error fetching branch geolocations:', error);
                });
        },
        initializeMap() {
            const galliMapsObject = {
                accessToken: 'c9c7a4c0-2834-4282-94f7-f4c79c40ab0a',
                map: {
                    container: 'displayMap',
                    center: [27.694569115640746, 85.3204940264128],
                    zoom: 12,
                    maxZoom: 25,
                    minZoom: 5,
                },
                pano: {
                    container: 'threedimension',
                },
            };

            const map = new GalliMapPlugin(galliMapsObject);

            if (!map) {
                console.error('Failed to initialize the map.');
                return;
            }

            this.branchGeolocations.forEach(location => {
                let pinMarkerObject = {
                    color: 'red',
                    draggable: false,
                    latLng: [parseFloat(location.latitude), parseFloat(location.longitude)],
                };

                let marker = map.displayPinMarker(pinMarkerObject);

                if (!marker) {
                    console.error('Failed to display the pin marker.');
                }
            });

            // Draw ward boundaries
            this.wardBoundaries.forEach(ward => {
                const lineStringOption = {
                    name: `ward-${ward.attributes.ward_no}`,
                    color: 'orange',
                    opacity: 0.5,
                    width: 4,
                    geoJson: {
                        type: 'Feature',
                        geometry: {
                            type: 'LineString',
                            coordinates: ward.geometry.rings[0]
                        }
                    }
                };
                map.drawPolygon(lineStringOption);
            });
        },
    },
    watch: {
        loading(newVal) {
            if (!newVal && this.branchGeolocations.length > 0) {
                this.initializeMap(); 
            }
        },
        chartSeries() {
            this.$nextTick(() => {
                this.$refs.pieChart.updateChartOptions(this.chartSeries, this.chartLabels);
                this.$refs.barChart.updateChartOptions([{ data: this.chartSeries }], this.chartLabels);
            });
        },
        chartLabels() {
            this.$nextTick(() => {
                this.$refs.pieChart.updateChartOptions(this.chartSeries, this.chartLabels);
                this.$refs.barChart.updateChartOptions([{ data: this.chartSeries }], this.chartLabels);
            });
        }
    }
};
</script>

<style scoped>
.upperDetails {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    height: max-content;
}

.infoCards,
.infoTable {
    width: 49.5%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.orgCards,
.branchCards {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 10px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.singleCard {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: start;
    gap: 4px;
    cursor: pointer;
    width: 80px;
}

.cardIcon {
    height: 80px;
    width: 80px;
    border: 1px solid #dedede;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardIconContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardCount {
    font-size: 40px;
    color: #3B5CA5;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    display: flex;
    justify-content: center;
    padding: 20px;
}

p {
    margin: 0 !important;
}

.cardLabel {
    width: 100%;
    text-align: center;
    font-size: 14px;
}

.indivCharDiv {
    border: 1px solid #dedede;
    border-radius: 8px;
    display: flex;
}

.indivCharDiv h4 {
    margin: 12px 0px 0px 12px;
}

.selected-card {
    border: 1px solid #3B5CA5;
}

/* Media query for smaller screens */
@media (max-width: 895px) {

    .infoCards,
    .infoTable {
        width: 100%;
    }
}
</style>
