<template>
    <div class="image-slider">
        <a-carousel ref="carousel" :autoplay="true" :dots="false">
            <div v-for="image in images" :key="image.branch_photos" class="carousel-slide">
                <img :src="image.branch_photos" alt="Branch Image" class="branch-image" />
            </div>
        </a-carousel>
        <div class="carousel-nav">
            <a-button @click="prevSlide" shape="circle" icon="left" class="carousel-button"></a-button>
            <a-button @click="nextSlide" shape="circle" icon="right" class="carousel-button"></a-button>
        </div>
    </div>
</template>

<script>
import { Carousel, Button } from 'ant-design-vue';

export default {
    name: 'ImageSlider',
    props: {
        images: {
            type: Array,
            required: true
        }
    },
    components: {
        'a-carousel': Carousel,
        'a-button': Button
    },
    methods: {
        prevSlide() {
            this.$refs.carousel.prev();
        },
        nextSlide() {
            this.$refs.carousel.next();
        }
    }
};
</script>

<style scoped>
.image-slider {
    position: relative;
}

.carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    background: #dddddd;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    /* position: absolute;
    top: 0;
    left: auto; */
}

.branch-image {
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin: auto;
    max-width: 80%;
}

.carousel-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.carousel-button {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>