<template>
  <div class="branch-page" v-if="branch">
    <div class="branchHeader">
      <h1>शौचालय प्रोफाइल</h1>
      <button class="editBtn" @click="handleEdit">
        <a-icon type="edit" /> एडिट
      </button>
    </div>
    <a-row :gutter="16">
      <a-col :span="15" class="full-width-on-small">
        <h3>नक्सामा शौचालयको ठेगाना</h3>
        <DisplayMap
          :latitude="branch.geo_location.latitude"
          :longitude="branch.geo_location.longitude"
        />
      </a-col>
      <a-col :span="9" class="full-width-on-small">
        <h3>शौचालय विवरण</h3>
        <div class="branch-details">
          <table>
            <tr>
              <td class="label"><strong>ठेगाना:</strong></td>
              <td>{{ branch.location }}</td>
            </tr>
            <tr>
              <td class="label"><strong>वडा नं:</strong></td>
              <td>{{ branch.ward }}</td>
            </tr>
            <tr>
              <td class="label"><strong>नगरपालिका/गाउँपालिका:</strong></td>
              <td>{{ branch.municipality }}</td>
            </tr>
            <tr>
              <td class="label"><strong>सेवा प्रकार:</strong></td>
              <td>
                {{
                  branch.service_type.toLowerCase() === "free"
                    ? "निःशुल्क"
                    : "स:शुल्क"
                }}
              </td>
            </tr>
            <tr>
              <td class="label"><strong>शौचालय प्रकार:</strong></td>
              <td>
                {{
                  branch.toilet_type.toLowerCase() === "male"
                    ? "पुरुष"
                    : branch.toilet_type.toLowerCase() === "female"
                    ? "महिला"
                    : "सबै"
                }}
              </td>
            </tr>
            <tr>
              <td class="label"><strong>अपांगमैत्री:</strong></td>
              <td>{{ branch.disabled_status ? "हो" : "होइन" }}</td>
            </tr>
            <tr>
              <td class="label"><strong>श्रेणी:</strong></td>
              <td>
                {{
                  branch.category.toLowerCase() === "private"
                    ? "निजी"
                    : "सार्वजनिक"
                }}
              </td>
            </tr>
            <tr>
              <td class="label"><strong>स्थिति:</strong></td>
              <td>
                {{
                  branch.status.toLowerCase() === "active"
                    ? "सक्रिय"
                    : "निष्क्रिय"
                }}
              </td>
            </tr>
            <tr>
              <td class="label"><strong>व्यवस्थापक संस्था:</strong></td>
              <td>{{ branch.organization_name }}</td>
            </tr>
            <tr>
              <td class="label">
                <strong>सञ्चालन गर्ने व्यक्तिको नाम:</strong>
              </td>
              <td>{{ branch.focal_person ? branch.focal_person : "N/A" }}</td>
            </tr>
            <tr>
              <td class="label"><strong>सम्पर्क नम्बर:</strong></td>
              <td>
                {{
                  branch.focal_person_contact
                    ? branch.focal_person_contact
                    : "N/A"
                }}
              </td>
            </tr>
            <tr v-show="branch.agreement_done === 1">
              <td class="label"><strong>सम्झौता भएको छ?:</strong></td>
              <td>
                {{
                  branch.agreement_done === null
                    ? "लागू हुँदैन"
                    : branch.agreement_done === 0
                    ? "छैन"
                    : "छ"
                }}
              </td>
            </tr>
            <tr v-show="branch.agreement_done === 1">
              <td class="label"><strong>सम्झौता मिति:</strong></td>
              <td>{{ branch.agreement_date }}</td>
            </tr>
            <tr v-show="branch.agreement_done === 1">
              <td class="label"><strong>सम्झौता अवधि:</strong></td>
              <td>{{ branch.agreement_duration }}</td>
            </tr>
          </table>
          <hr />
          <div class="qrDiv">
            <img
              class="qr"
              :src="branch.branch_QR_code"
              alt="QR code for this branch doesn't exist"
            />
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row style="margin: 12px 0px" :gutter="16" class="branch-inventory">
      <a-col :span="12" class="full-width-on-small">
        <h3>सामग्री निर्धारण</h3>
        <a-form
          @submit="handleAssign"
          layout="vertical"
          :key="assignFormComponentKey"
        >
          <a-form-item label="निर्धारित सामग्री">
            <a-select
              show-search
              placeholder="Item चयन गर्नुहोस्"
              option-filter-prop="children"
              :filter-option="filterOption"
              v-model="assignFormState.item_id"
            >
              <a-select-option
                v-for="item in allInventoryItems"
                :key="item.id"
                :value="item.id"
              >
                {{ item.item_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="निर्धारित मात्रा">
            <a-input
              v-model="assignFormState.quantity"
              type="number"
              min="0"
              required
            />
          </a-form-item>
          <a-form-item label="निर्धारित मिति">
            <VNepaliDatePicker
              calenderType="Nepali"
              placeholder="0000-00-00"
              format="yyyy-mm-dd"
              classValue="nepalidatepicker"
              v-on:change="dateChange"
              value
              class="datepicker"
              style="margin-bottom: 10px"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              style="background-color: #2c68aa"
              type="primary"
              html-type="submit"
            >
              निर्धारण गर्नुहोस्
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="12" class="full-width-on-small">
        <h3>निर्धारित सामग्री सूची</h3>
        <a-form class="form" layout="inline" @submit="handleSearchInvList">
          <a-form-item label="मिति छान्नुहोस्">
            <VNepaliDatePicker
              calenderType="Nepali"
              placeholder="0000-00-00"
              format="yyyy-mm-dd"
              classValue="nepalidatepicker"
              v-on:change="searchDateChange"
              value
              class="datepicker"
              style="margin-bottom: 10px"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              style="background-color: #2c68aa"
              type="primary"
              html-type="submit"
            >
              खोज्नुहोस्
            </a-button>
          </a-form-item>
        </a-form>
        <div class="itemListOfThatDate">
          <a-table
            :columns="columns"
            :data-source="inventoryItemListOfADate"
            :pagination="false"
            bordered
          >
            <template slot="sn" slot-scope="text, record">
              {{ record.sn }}
            </template>
            <template slot="item_name" slot-scope="text, record">
              {{ record.inventory_name }}
            </template>
            <template slot="quantity" slot-scope="text, record">
              {{ record.quantity }}
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
    <a-row :gutter="16" class="branch-images">
      <a-col :span="24">
        <h3>शौचालयका तस्वीरहरू</h3>
        <ImageSlider :images="branch.branch_images" />
      </a-col>
    </a-row>
  </div>
  <!-- <div v-else>
    <h2>लोड हुँदैछ, कृपया पर्खनुहोस्</h2>
  </div> -->
  <a-spin
    v-else
    class="loader"
    :spinning="loading"
    tip="लोड हुँदैछ..."
  ></a-spin>
</template>

<script>
import { Row, Col, Card } from "ant-design-vue";
import PublicShauchalaya from "../../services/PublicShauchalaya";
import DisplayMap from "../../components/Maps/DisplayMap.vue";
import ImageSlider from "../../components/Branch/ImageSlider.vue";
import VNepaliDatePicker from "v-nepalidatepicker";
import { message } from "ant-design-vue";

export default {
  props: ["id"],
  components: {
    "a-row": Row,
    "a-col": Col,
    "a-card": Card,
    DisplayMap,
    ImageSlider,
    VNepaliDatePicker,
  },
  data() {
    return {
      branch: null,
      allInventoryItems: [],
      assignFormState: this.getInitialFormState(),
      dateToGetInventoryItemList: "",
      assignFormComponentKey: 0,
      inventoryItemListOfADate: [],
      columns: [
        {
          title: "S.N.",
          dataIndex: "sn",
          key: "sn",
        },
        {
          title: "सामग्रीको नाम",
          dataIndex: "inventory_name",
          key: "inventory_name",
        },
        {
          title: "मात्रा",
          dataIndex: "quantity",
          key: "quantity",
        },
      ],
    };
  },
  mounted() {
    this.fetchBranchData();
    this.fetchAllInventoryItems();
  },
  methods: {
    getInitialFormState() {
      return {
        item_id: null,
        branch_id: this.id,
        quantity: null,
        allocated_date: "",
      };
    },
    fetchBranchData() {
      PublicShauchalaya.getIndividualBranch(this.id)
        .then((response) => {
          this.branch = response.data.data;
          this.inventoryItemListOfADate = this.branch.branch_inventory.map(
            (item, index) => ({
              sn: index + 1,
              inventory_name: item.inventory_name,
              quantity: item.quantity,
            })
          );
        })
        .catch((error) => {
          console.error("Error fetching branch data:", error);
        });
    },
    fetchAllInventoryItems() {
      PublicShauchalaya.getInventoryList()
        .then((res) => {
          this.allInventoryItems = res.data.data;
        })
        .catch((err) => {
          console.error("Error fetching inventory items:", err);
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    dateChange(newDate) {
      this.assignFormState.allocated_date = newDate;
    },
    searchDateChange(newDate) {
      this.dateToGetInventoryItemList = newDate;
    },
    handleEdit() {
      this.$router.push(`/branch/edit/${this.id}`);
    },
    handleAssign(e) {
      e.preventDefault();

      const formData = new FormData();
      formData.append("id", this.assignFormState.item_id);
      formData.append("branch_id", this.id);
      formData.append("quantity", this.assignFormState.quantity);
      formData.append("allocated_date", this.assignFormState.allocated_date);
      PublicShauchalaya.assignItemToBranch(formData).then((res) => {
        message.success("सामग्री निर्धारण भयो !");
        this.resetForm();
        this.assignFormComponentKey += 1;
      });
    },
    resetForm() {
      this.assignFormState = this.getInitialFormState();
    },
    handleSearchInvList(e) {
      e.preventDefault();

      const formData = new FormData();
      formData.append("allocated_date", this.dateToGetInventoryItemList);
      formData.append("branch_id", this.id);

      PublicShauchalaya.fetchBranchInventoryFromDate(formData).then((res) => {
        const items = res.data.data;
        const formattedData = items.map((item, index) => {
          return {
            sn: index + 1,
            inventory_name: item.inventory_name,
            quantity: item.quantity,
          };
        });
        this.inventoryItemListOfADate = formattedData;

        const allocatedDate = this.dateToGetInventoryItemList;
        message.info(`(${allocatedDate}) मितिको सामग्री सूची खोजियो छ !`);
      });
    },
  },
};
</script>

<style scoped>
.branch-page {
  padding: 20px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

.organization-image {
  width: 100%;
  height: auto;
}

.branch-details {
  margin: 0;
  font-size: 14px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  min-height: 600px;
}

.branch-details table {
  width: 100%;
  border-collapse: collapse;
}

.branch-details td {
  padding: 8px;
  border: none;
}

.branch-details .label {
  width: 50%;
  font-weight: bold;
}

.branch-details td:not(.label) {
  width: 50%;
}

.branch-images h3 {
  margin: 16px 0;
}

.branch-image-card {
  margin-bottom: 20px;
}

.branch-image {
  width: 100%;
  height: auto;
}

.qrDiv {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr {
  height: 100px;
  width: 100px;
}

.form {
  margin: 12px 0px;
}

.branchHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editBtn {
  padding: 4px 8px;
  border-radius: 2px;
  cursor: pointer;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100px;
  gap: 5px;
  color: #3B5CA5;
  border: 1px solid #3B5CA5;
}

.loader {
  display: flex;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 800px) {
  .full-width-on-small {
    width: 100% !important;
    margin-bottom: 16px;
  }
}
</style>

<style>
.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
</style>
