<template>
    <div>
        <a-form class="form" layout="inline" @submit="handleSubmit">
            <a-form-item label="सामग्रीको नाम">
                <a-input v-model="formState.itemName" type="text" required />
            </a-form-item>
            <a-form-item>
                <a-button style="background-color: #2c68aa;" type="primary" html-type="submit">
                    सामग्री दर्ता
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import PublicShauchalaya from '../../services/PublicShauchalaya';
import { message } from "ant-design-vue";

export default {
    data() {
        return {
            formState: {
                itemName: ''
            }
        }
    },
    methods: {
        handleSubmit(e) {
            // console.log('handleSubmit called');
            e.preventDefault()
            const formData = new FormData();
            formData.append('item_name', this.formState.itemName);
            PublicShauchalaya.createInventoryItem(formData)
                .then((res) => {
                    this.resetForm();
                    this.$parent.$emit('refresh');
                    message.success("नयाँ सामग्री दर्ता भयो !");
                })
        },
        resetForm() {
            this.formState.itemName = ''
        },
    }
}
</script>

<style scoped></style>