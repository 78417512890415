<template>
  <div class="cont">
    <div class="formDiv" :key="componentKey">
      <div style="justify-content: start" class="heading">
        <a-icon style="color: #23c400" class="headerIcon" type="form" /> शौचालय
        एडिट गर्नुहोस्
      </div>
      <a-form-model class="form" @submit="handleSubmit" layout="vertical">
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="शौचालयको मूल संस्था"
            >
              <div class="static-input">{{ formState.organization_name }}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="शौचालयको ठेगाना">
              <a-input v-model="formState.location" type="text" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="शौचालयको प्रकार">
              <a-select v-model="formState.toilet_type">
                <a-select-option value="male">पुरुष</a-select-option>
                <a-select-option value="female">महिला</a-select-option>
                <a-select-option value="all">सबै</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="शौचालयको वर्ग">
              <a-select v-model="formState.category">
                <a-select-option value="private">निजी</a-select-option>
                <a-select-option value="public">सार्वजनिक</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="शौचालयको स्थिति">
              <a-select v-model="formState.status">
                <a-select-option value="1">सक्रिय</a-select-option>
                <a-select-option value="0">निष्क्रिय</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="सेवाको प्रकार">
              <a-select v-model="formState.service_type">
                <a-select-option value="paid">श:शुल्क</a-select-option>
                <a-select-option value="free">नि:शुल्क</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="अपाङ्गहरूले प्रयोग गर्न सक्छन्?"
            >
              <a-select v-model="formState.disabled_status">
                <a-select-option value="1">सक्छन्</a-select-option>
                <a-select-option value="0">सक्दैनन्</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              v-bind="formItemLayout"
              label="अक्षांश (Latitude)"
            >
              <a-input v-model="formState.latitude" type="text" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item
              v-bind="formItemLayout"
              label="देशान्तर (Longitude)"
            >
              <a-input v-model="formState.longitude" type="text" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="वडा नं:">
              <a-input v-model="formState.ward" type="text" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="नगरपालिका/गाउँपालिका"
            >
              <a-input v-model="formState.municipality" type="text" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item
              v-bind="formItemLayout"
              label="सञ्चालन गर्ने व्यक्तिको नाम"
            >
              <a-input v-model="formState.focal_person" type="text" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="सम्पर्क नम्बर">
              <a-input v-model="formState.focal_person_contact" type="text" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="24">
            <a-form-model-item v-bind="formItemLayout" label="सम्झौता भएको छ?">
              <a-radio-group
                v-model="formState.agreement_done"
                @change="handleAgreementChange"
              >
                <a-radio :value="1">छ</a-radio>
                <a-radio :value="0">छैन</a-radio>
                <a-radio :value="null">लागू हुँदैन</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="formState.agreement_done == '1'" :gutter="[8, 8]">
          <a-col :span="12">
            <a-form-model-item label="सम्झौता मिति" prop="agreement_date">
              <VNepaliDatePicker
                calenderType="Nepali"
                :placeholder="branchAgreementPlaceholder"
                format="yyyy-mm-dd"
                classValue="nepalidatepicker"
                v-on:change="dateChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item v-bind="formItemLayout" label="सम्झौता अवधि">
              <a-input v-model="formState.agreement_duration" type="number"  min="0"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="[8, 8]">
          <a-col :span="24">
            <a-form-model-item
              v-bind="formItemLayout"
              label="शौचालयका तस्बिरहरू अपलोड गर्नुहोस्"
            >
              <ImageUploader
                ref="imageUploader"
                :initialImages="formState.branch_images"
                @update-images="updateImages"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item>
          <a-button
            style="background-color: #2c68aa"
            type="primary"
            html-type="submit"
            :loading="isSubmitting"
          >
            सेभ गर्नुहोस्
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import PublicShauchalaya from "../../services/PublicShauchalaya";
import ImageUploader from "../../components/Branch/ImageUploader.vue";
import VNepaliDatePicker from "v-nepalidatepicker";
import { message } from "ant-design-vue";

export default {
  components: {
    ImageUploader,
    VNepaliDatePicker,
  },
  props: ["id"],
  data() {
    return {
      formState: this.getInitialFormState(),
      organizations: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 24 },
        },
      },
      componentKey: 0,
      isSubmitting: false,
      branchAgreementPlaceholder: "००००-००-००",
    };
  },
  mounted() {
    this.fetchOrganizations();
    this.fetchBranchDetails();
  },
  methods: {
    getInitialFormState() {
      return {
        organization_id: null,
        organization_name: "",
        toilet_type: "",
        category: "",
        status: "",
        disabled_status: "",
        service_type: "",
        location: "",
        latitude: "",
        longitude: "",
        ward: "",
        municipality: "",
        focal_person: "",
        focal_person_contact: "",
        agreement_done: null,
        agreement_date: null,
        agreement_duration: null,
        branch_images: [],
      };
    },
    fetchOrganizations() {
      PublicShauchalaya.getOrganizationNameList()
        .then((response) => {
          this.organizations = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching organizations:", error);
        });
    },
    fetchBranchDetails() {
      PublicShauchalaya.getIndividualBranch(this.id)
        .then((response) => {
          const branch = response.data.data;
          this.formState = {
            organization_id: branch.organization_id,
            organization_name: branch.organization_name,
            toilet_type: branch.toilet_type.toLowerCase(),
            category: branch.category.toLowerCase(),
            status: branch.status.toLowerCase() === "inactive" ? "0" : "1",
            disabled_status: branch.disabled_status ? "1" : "0",
            service_type:
              branch.service_type.toLowerCase() === "paid" ? "paid" : "free",
            location: branch.location,
            latitude: branch.geo_location.latitude,
            longitude: branch.geo_location.longitude,
            ward: branch.ward,
            municipality: branch.municipality,
            focal_person: branch.focal_person,
            focal_person_contact: branch.focal_person_contact,
            agreement_done: branch.agreement_done
              ? "1"
              : branch.agreement_done === false
              ? "0"
              : null,
            agreement_date: branch.agreement_date,
            agreement_duration: branch.agreement_duration,
            branch_images: branch.branch_images || [],
          };
        })
        .catch((error) => {
          console.error("Error fetching branch details:", error);
        });
    },
    updateImages(images) {
      this.formState.branch_images = images.map((image) => image.file);
    },
    dateChange(newDate) {
      this.formState.agreement_date = newDate;
    },
    handleAgreementChange() {
      if (this.formState.agreement_done !== "1") {
        this.formState.agreement_date = null;
        this.formState.agreement_duration = null;
      }
    },
    handleSubmit(e) {
      e.preventDefault();

      this.isSubmitting = true;
      const formData = new FormData();
      formData.append("id", this.id);
      formData.append("toilet_type", this.formState.toilet_type);
      formData.append("category", this.formState.category);
      formData.append("status", this.formState.status);
      formData.append("service_type", this.formState.service_type);
      formData.append("location", this.formState.location);
      formData.append("disabled_status", this.formState.disabled_status);
      formData.append("agreement_done", this.formState.agreement_done);
      formData.append("agreement_date", this.formState.agreement_date);
      formData.append("agreement_duration", this.formState.agreement_duration);
      formData.append("focal_person", this.formState.focal_person);
      formData.append(
        "focal_person_contact",
        this.formState.focal_person_contact
      );
      formData.append("latitude", this.formState.latitude);
      formData.append("longitude", this.formState.longitude);
      formData.append("ward", this.formState.ward);
      formData.append("municipality", this.formState.municipality);

      this.formState.branch_images.forEach((file) => {
        formData.append("branch_images[]", file);
      });

      PublicShauchalaya.updateBranch(formData)
        .then((response) => {
          message.success("शौचालय अपडेट भयो !");
          this.$router.push(`/branch/${this.id}`);
        })
        .catch((error) => {
          message.error("शौचालय अपडेटमा समस्या आयोको छ !");
          console.error("Error updating branch:", error);
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>

<style scoped>
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}

.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}

.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.cont {
  width: 100%;
  display: flex;
  overflow-x: hidden;
  gap: 4px;
}

.heading {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  text-align: left;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}

.subHeading {
  display: flex;
  align-items: center;
}

.headerIcon {
  font-size: x-large;
  margin: 0 12px;
}

.formDiv {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.form {
  padding: 12px;
}

.table {
  padding: 4px 12px;
}

.ant-input-number-handler-wrap {
  display: none;
}

.static-input {
  height: 33px;
  padding: 8px;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .cont {
    flex-direction: column;
  }

  .formDiv {
    width: 100%;
  }
}
</style>

<style>
.datepicker {
  padding: 0px !important;
  width: 100%;
}

.nepalidatepicker {
  box-sizing: border-box;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  width: 100% !important;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

.nepalidatepicker:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
</style>
