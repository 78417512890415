import axios from '../plugins/axios' // Adjust the path as necessary

export default {
  handleLogin(values) {
    return axios.post('/loginapi/login', values)
  },
  // handleLogout() {
  //   return axios.post('/loginapi/logout')
  // },
}
