<template>
    <div id="bar-chart">
        <apexchart type="bar" height="200" :options="chartOptions" :series="series" ref="barChart">
        </apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        apexchart: VueApexCharts
    },
    props: {
        series: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                colors: ['#3B5CA5', '#008FFB', '#ff4560',],
                chart: {
                    type: 'bar',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        distributed: true,
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: this.labels,
                },
                yaxis: {
                    title: {
                        text: 'Count'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val;
                        }
                    }
                },
                legend: {
                    position: 'top',
                    fontSize: '14px',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom',
                            fontSize: '10px',
                        }
                    }
                }]
            }
        };
    },
    watch: {
        series(newSeries) {
            this.updateChartOptions(newSeries, this.labels);
        },
        labels(newLabels) {
            this.updateChartOptions(this.series, newLabels);
        }
    },
    methods: {
        updateChartOptions(series, labels) {
            this.chartOptions = {
                ...this.chartOptions,
                xaxis: {
                    ...this.chartOptions.xaxis,
                    categories: labels
                }
            };
            this.$nextTick(() => {
                if (this.$refs.barChart) {
                    this.$refs.barChart.updateSeries(series, true);
                }
            });
        }
    }
};
</script>

<style scoped>
#bar-chart {
    max-width: 380px;
    margin: 20px auto;
}
</style>
