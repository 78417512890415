<template>
    <div v-show="this.$route.path !== '/login'" class="cont">
        <div class="logoAndName">
            <img class="headerImage" src="../assets/publicShuachalayaHeaderImage.png" alt="">
            <!-- <div class="logoDiv">
                <img class="logo" src="../assets/logo.jpg" alt="">
            </div>
            <div class="nameDiv">
                <div class="sitename">सार्वजनिक शौचालय प्रबन्धन</div>
                <p>काठमाडौँ महानगरपालिका</p>
            </div> -->
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.cont {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    box-shadow: 5px 5px 5px #dedede;
}

.logoAndName {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoDiv,
.nameDiv {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 8px;
}
.nameDiv{
    flex-direction: column;
    align-items: start;
}
.headerImage{
    height: 75px;
    width: 100%;
    object-fit: cover;
}
.logo {
    height: 75px;
    width: 75px;
    pointer-events: none;
}

.sitename {
    font-size: 24px;
    font-weight: 600;
    color: #000;
}
</style>