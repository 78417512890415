<template>
  <div id="app">
    <global-header />
    <div :class="appClass">
      <Header />
      <router-view />
    </div>
  </div>
</template>

<script>
import GlobalHeader from './components/GlobalHeader.vue';
import Header from './components/Header.vue'
export default {
  name: 'App',
  components: {
    Header,
    GlobalHeader
  },
  computed: {
    appClass() {
      return this.$route.path === '/login' ? '' : 'app-padding';
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-padding {
  padding: 8px 48px;
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 4px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #3B5CA5;
  border-radius: 10px;
  width: 4px;
}
</style>
