<template>
  <div v-show="this.$route.path !== '/login'" class="headerCont">
    <div class="allLinks">
      <div class="linksDiv">
        <router-link to="/" exact>
          <div class="card">
            <img src="../assets/headericons/dashboard.svg" alt="" />
            <div class="cardTitle">विस्तृत तथ्याङ्क</div>
          </div>
        </router-link>
        <router-link to="/organization/register" exact>
          <div class="card">
            <img src="../assets/headericons/org.svg" alt="" />
            <div class="cardTitle">व्यवस्थापक संस्था</div>
          </div>
        </router-link>
        <router-link to="/branch/register" exact>
          <div class="card">
            <img src="../assets/headericons/toilet.svg" alt="" />
            <div class="cardTitle">शौचालय</div>
          </div>
        </router-link>
        <router-link to="/inventory/index" exact>
          <div class="card">
            <img
              style="width: 55px"
              src="../assets/headericons/inventory.svg"
              alt=""
            />
            <div class="cardTitle">भण्डार</div>
          </div>
        </router-link>
        <router-link to="/verification/index" exact>
          <div class="card">
            <img
              style="width: 35px"
              src="../assets/headericons/verifyOrg.svg"
              alt=""
            />
            <div class="cardTitle">संस्था प्रमाणीकरण</div>
          </div>
        </router-link>
        <router-link to="/review/index" exact>
          <div class="card">
            <img
              style="width: 55px"
              src="../assets/headericons/review.svg"
              alt=""
            />
            <div class="cardTitle">उपभोक्ता समीक्षा</div>
          </div>
        </router-link>
        <router-link to="/feedback/index" exact>
          <div class="card">
            <img
              style="width: 55px"
              src="../assets/headericons/feedback.svg"
              alt=""
            />
            <div class="cardTitle">उप. प्रतिक्रिया</div>
          </div>
        </router-link>
      </div>
      <div class="linksDiv">
        <a-popconfirm
          title="के तपाईं लगआउटको लागि निश्चित हुनुहुन्छ?"
          cancel-text="होइन"
          ok-text="हो"
          @confirm="handleLogout"
          @cancel="cancel"
          placement="bottom"
        >
          <div class="card">
            <!-- <div @click="handleLogout" class="card"> -->
            <!-- <a-spin v-if="isLoading" /> -->
            <!-- <img v-else src="../assets/headericons/logout.svg" alt="" /> -->
            <img style="width: 35px" src="../assets/headericons/logout.svg" alt="" />
            <div class="cardTitle">लग आउट</div>
          </div>
        </a-popconfirm>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from "@/services/Auth";
import Cookies from "js-cookie";
import { Spin } from "ant-design-vue";
import { message } from "ant-design-vue";

export default {
  components: {
    "a-spin": Spin,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    handleLogout() {
      this.isLoading = true;
      Cookies.remove("toilet_token");
      this.$router.push("/login");
      message.success("लगआउट सफल भयो।");
      // Auth.handleLogout()
      //     .then((res) => {
      //         Cookies.remove('toilet_token');
      //         this.$router.push('/login');
      //     })
      //     .catch((error) => {
      //         console.error('Logout Error:', error);
      //     })
      //     .finally(() => {
      //         this.isLoading = false;
      //     });
    },
    cancel() {
      message.info("लगआउट रद्द गरिएको छ।");
    },
  },
};
</script>

<style scoped>
.headerCont {
  padding: 0px;
}

.dashboard-card-wrapper {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 10px;
  width: 100%;
}

.card-caption {
  text-align: center;
  background: #fff;
  margin-top: -30px;
  width: 100px;
  font-size: 1.6em;
  margin-bottom: 10px;
}

.allLinks {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(210, 210, 210);
  border-radius: 12px;
  margin: 12px 0px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.allLinks::-webkit-scrollbar {
  display: none;
}

.linksDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  font-size: 12px;
  height: 90px;
  width: 90px;
  margin: 12px;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: 1s ease-in-out;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}

.card img {
  width: 36px;
  height: 36px;
  position: absolute;
  bottom: 36px;
  left: auto;
}

.cardTitle {
  font-size: 12px;
  position: absolute;
  bottom: 4px;
  left: auto;
}

.router-link-exact-active .card {
  border-color: #3B5CA5 !important;
}

@media (max-width: 768px) {
  .allLinks {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .allLinks::-webkit-scrollbar {
    display: none;
  }

  .card {
    flex-shrink: 0;
  }
}
</style>
