import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import axios from 'axios'
import Login from '../views/Login.vue'
import Index from '../views/Dashboard/Index.vue'
import Organization from '../views/Organization/Organization.vue'
import ListOfOrganizations from '../views/Organization/ListOfOrganizations.vue'
import RegisterOrganization from '../views/Organization/RegisterOrganization.vue'
import EditOrganization from '../views/Organization/EditOrganization.vue'
import Branch from '../views/Branch/Branch.vue'
import ListOfBranches from '../views/Branch/ListOfToilets.vue'
import RegisterBranch from '../views/Branch/RegisterBranch.vue'
import EditBranch from '../views/Branch/EditBranch.vue'
import InventoryIndex from '../views/Inventory/InventoryIndex.vue'
import VerificationIndex from '../views/VerifyOrganization/VerificationIndex.vue'
import FeedbackIndex from '../views/Feedback/FeedbackIndex.vue'
import ReviewIndex from '../views/Review/ReviewIndex.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'PublicShauchalayaLogin',
    component: Login,
  },
  {
    path: '/',
    name: 'PublicShauchalaya',
    component: Index,
  },
  {
    path: '/organization/register',
    name: 'PublicShauchalayaOrganizationRegister',
    component: RegisterOrganization,
    props: true,
  },
  {
    path: '/organization/list',
    name: 'ListOfOrganizations',
    component: ListOfOrganizations,
    props: true,
  },
  {
    path: '/organization/:id',
    name: 'PublicShauchalayaOrganization',
    component: Organization,
    props: true,
  },
  {
    path: '/organization/edit/:id',
    name: 'PublicShauchalayaOrganizationEdit',
    component: EditOrganization,
    props: true,
  },
  {
    path: '/branch/register',
    name: 'PublicShauchalayaBranchRegister',
    component: RegisterBranch,
    props: true,
  },
  {
    path: '/toilet/list',
    name: 'ListOfToilets',
    component: ListOfBranches,
    props: true,
  },
  {
    path: '/branch/:id',
    name: 'PublicShauchalayaBranch',
    component: Branch,
    props: true,
  },
  {
    path: '/branch/edit/:id',
    name: 'PublicShauchalayaBranchEdit',
    component: EditBranch,
    props: true,
  },
  {
    path: '/inventory/index',
    name: 'PublicShauchalayaInventoryIndex',
    component: InventoryIndex,
    props: true,
  },
  {
    path: '/verification/index',
    name: 'PublicShauchalayaVerificationIndex',
    component: VerificationIndex,
    props: true,
  },
  {
    path: '/review/index',
    name: 'PublicShauchalayaReviewIndex',
    component: ReviewIndex,
    props: true,
  },
  {
    path: '/feedback/index',
    name: 'PublicShauchalayaFeedbackIndex',
    component: FeedbackIndex,
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = Cookies.get('toilet_token')

  if (to.path === '/login' && token) {
    next('/')
  } else if (to.path !== '/login' && !token) {
    next('/login')
  } else {
    next()
  }
})

export default router
