<template>
    <div id="chart">
        <apexchart type="pie" height="200" width="200" :options="chartOptions" :series="series" ref="chart"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
    components: {
        apexchart: VueApexCharts
    },
    props: {
        series: {
            type: Array,
            required: true
        },
        labels: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            chartOptions: {
                colors: ['#3B5CA5', '#008FFB', '#ff4560', '#2F61AC',],
                chart: {
                    type: 'pie',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: {
                            enabled: true,
                            delay: 150
                        },
                        dynamicAnimation: {
                            enabled: true,
                            speed: 350
                        }
                    }
                },
                labels: this.labels,
                legend: {
                    position: 'top',
                    fontSize: '14px',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom',
                            fontSize: '10px',
                        }
                    }
                }]
            }
        };
    },
    watch: {
        series(newSeries) {
            this.updateChartOptions(newSeries, this.labels);
        },
        labels(newLabels) {
            this.updateChartOptions(this.series, newLabels);
        }
    },
    methods: {
        updateChartOptions(series, labels) {
            this.chartOptions = {
                ...this.chartOptions,
                labels: labels
            };
            this.$nextTick(() => {
                if (this.$refs.chart) {
                    this.$refs.chart.updateSeries(series, true);
                }
            });
        }
    },
};
</script>

<style scoped>
#chart {
    max-width: 380px;
    margin: 20px auto;
}
</style>

<!-- last one  -->
