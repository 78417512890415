<template>
  <a-modal :visible="localVisible" @ok="handleOk" @cancel="handleCancel" width="80%">

    <div id="map" style="height: 500px;"></div>
    <div id="threedimension"></div>
  </a-modal>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localVisible: this.visible,
      map: null,
      marker: null,
      selectedLocation: null,
    };
  },
  watch: {
    visible(val) {
      this.localVisible = val;
      if (val) {
        this.$nextTick(() => {
          if (!this.map) {
            this.initializeMap();
          } else {
            this.map.map.resize();
          }
        });
      }
    },
    localVisible(val) {
      this.$emit('update:visible', val);
    },
  },
  methods: {
    initializeMap() {
      const customClickFunction = (event) => {
        const { lat, lng } = event.lngLat;
        // console.log('Clicked location:', lat, lng);

        if (this.marker) {
          this.marker.remove();
        }

        let pinMarkerObject = {
          color: "#FF0000",
          draggable: false,
          latLng: [lat, lng],
        };

        this.marker = this.map.displayPinMarker(pinMarkerObject);
        this.selectedLocation = { lat, lng };
      };

      const galliMapsObject = {
        accessToken: 'c9c7a4c0-2834-4282-94f7-f4c79c40ab0a',
        map: {
          container: 'map',
          center: [27.7172, 85.3240],
          zoom: 12,
          maxZoom: 25,
          minZoom: 5,
          clickable: true,
        },
        pano: { container: 'threedimension' },
        customClickFunctions: [customClickFunction],
      };

      // Initialize GalliMaps Object
      this.map = new GalliMapPlugin(galliMapsObject);
    },
    handleOk() {
      if (this.selectedLocation) {
        this.$emit('locationSelected', [this.selectedLocation.lat, this.selectedLocation.lng]);
      }
      this.localVisible = false;
    },
    handleCancel() {
      this.localVisible = false;
    },
  },
};
</script>

<style>
#map {
  height: 500px;
  width: 100%;
}
</style>
