import axios from "axios";
import Cookies from "js-cookie";

const axiosInstance = axios.create({
  // baseURL: "https://erp.kathmandu.gov.np/", // kathmandu module
  baseURL: "https://publicshauchalaya.palika.site/", // for Production
  // baseURL: 'http://sp_kathmandumetro.test', // for Local Development
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("toilet_token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
