<template>
  <div>
    <div class="topInvDetails">
      <!-- <div class="leftTopInvDetails"></div> -->
      <div class="righttTopInvDetails">
        <CreateInventoryItem />
                <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
        <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
        <div v-else class="inventoryItemsTable">
                    <a-table :columns="columns" :data-source="allInventoryItems" :pagination='false' bordered>
                        <template slot="sn" slot-scope="text, record ">
              {{ record.sn }}
            </template>
                        <template slot="item_name" slot-scope="text, record ">
              {{ record.item_name }}
            </template>
            <template slot="action" slot-scope="record">
              <!-- <a-button title="हटाउनुहोस्" class="actionBtn" style="color: red;"
                                @click="handleDelete(record.id)"><a-icon type="delete" /></a-button> -->
              <a-popconfirm
                title="के तपाईं पक्का यो भण्डार मेट्न चाहनुहुन्छ?"
                ok-text="हो"
                cancel-text="होइन"
                @confirm="handleDelete(record.id)"
                placement="bottom"
              >
                <a-button
                  title="हटाउनुहोस्"
                  class="actionBtn"
                  style="color: red"
                >
                  <a-icon type="delete" />
                </a-button>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicShauchalaya from '../../services/PublicShauchalaya'
import CreateInventoryItem from './CreateInventoryItem.vue'
import { message } from "ant-design-vue";

export default {
  components: { CreateInventoryItem },
  data() {
    return {
      loading: true,
      allInventoryItems: [],
      columns: [
        {
                    title: 'S.N.',
                    dataIndex: 'sn',
                    key: 'sn',
        },
        {
                    title: 'सामग्रीको नाम',
                    dataIndex: 'item_name',
                    key: 'item_name',
        },
        {
                    title: 'कार्य',
                    key: 'action',
                    scopedSlots: { customRender: 'action' },
                }
      ],
        }
  },
  mounted() {
    this.fetchAllInventoryItems();
  },
  created() {
    this.fetchAllInventoryItems();
        this.$on('refresh', this.fetchAllInventoryItems);
  },
  beforeDestroy() {
        this.$off('refresh', this.fetchAllInventoryItems);
  },
  methods: {
    fetchAllInventoryItems() {
      PublicShauchalaya.getInventoryList()
        .then((response) => {
          const items = response.data.data;
          const formattedData = items.map((item, index) => {
            return {
              sn: index + 1,
              item_name: item.item_name,
              id: item.id,
                        }
                    })
                    this.allInventoryItems = formattedData
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleDelete(itemId) {
            // if (confirm('तपाईं पक्का यो सामग्री मेट्न चाहनुहुन्छ?')) {
                PublicShauchalaya.deleteInventoryItem(itemId)
                    .then(() => {
          this.fetchAllInventoryItems();
          message.success("सामग्री सफलतापूर्वक मेटिएको छ!");
                    })
    //   }

    },
  },
}
</script>

<style scoped>
.topInvDetails {
  display: flex;
}

.leftTopInvDetails {
  width: 0%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 4px;
}

.righttTopInvDetails {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.actionBtn {
  background-color: transparent;
  border: none;
  font-size: larger;
  box-shadow: none;
}

.loader{
  padding: 20px;
}
</style>
