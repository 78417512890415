<template>
  <div class="cont">
    <div v-show="this.$route.name === 'ListOfToilets'" class="headerTab">
      <div class="heading">
        <a-icon style="color: #41cb30" type="unordered-list" /> शौचालय सूची
      </div>
      <div
        style="cursor: pointer"
        class="newReg heading"
        type="primary"
        @click="() => $router.push('/branch/register')"
      >
        <a-icon style="color: red" type="plus-circle" /> नयाँ शौचालय दर्ता
        गर्नुहोस्
      </div>
    </div>
    <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
    <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
    <div v-else class="tableNPagination">
      <!-- <a-spin :spinning="loading" tip="Loading..."> -->
      <a-table
        style="width: 100%"
        :data-source="data"
        :columns="filteredColumns"
        :pagination="false"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${column.title} खोज्नुहोस्`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
          >
            खोज्नुहोस्
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
          >
            रिसेट
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span
            class="capitalize"
            v-if="searchText && searchedColumn === column.dataIndex"
          >
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <span class="capitalize">{{ text }}</span>
          </template>
        </template>

        <template slot="actions" slot-scope="text, record">
          <a-button
            title="हेर्नुहोस्"
            class="actionBtn"
            style="color: blue"
            @click="handleView(record.key)"
            ><a-icon type="eye"
          /></a-button>
          <!-- <a-button title="मिलाउनुहोस्" class="actionBtn" style="color: green;" @click="handleEdit(record.key)"><a-icon
            type="edit" /></a-button> -->
          <!-- <a-button
            title="हटाउनुहोस्"
            class="actionBtn"
            style="color: red"
            @click="handleDelete(record.key)"
            ><a-icon type="delete"
          /></a-button> -->
          <a-popconfirm
            title="के तपाईं पक्का यो शौचालय मेट्न चाहनुहुन्छ?"
            ok-text="हो"
            cancel-text="होइन"
            @confirm="handleDelete(record.key)"
            placement="bottom"
          >
            <a-button title="हटाउनुहोस्" class="actionBtn" style="color: red">
              <a-icon type="delete" />
            </a-button>
          </a-popconfirm>
        </template>
      </a-table>
      <a-pagination
        :current="currentPage"
        :total="total"
        :pageSize="pageSize"
        @change="handlePageChange"
      />
      <!-- </a-spin> -->
    </div>
  </div>
</template>

<script>
import PublicShauchalaya from "../../services/PublicShauchalaya";
import { message } from "ant-design-vue";

export default {
  data() {
    return {
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      columns: [
        {
          title: "S.N.",
          dataIndex: "sn",
          key: "sn",
        },
        {
          title: "ठेगाना",
          dataIndex: "location",
          key: "location",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.location;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "व्यवस्थापक संस्था",
          dataIndex: "organization_name",
          key: "organization_name",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.organization_name;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "प्रकार",
          dataIndex: "toilet_type",
          key: "toilet_type",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.toilet_type;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "वर्ग",
          dataIndex: "category",
          key: "category",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.category;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "स्थिति",
          dataIndex: "status",
          key: "status",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.status;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "सेवाको प्रकार",
          dataIndex: "service_type",
          key: "service_type",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "customRender",
          },
          onFilter: (value, record) => {
            const field = record.service_type;
            return (
              field &&
              field.toString().toLowerCase().includes(value.toLowerCase())
            );
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              }, 0);
            }
          },
        },
        {
          title: "कार्यहरू",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],
    };
  },
  created() {
    this.fetchData();
    this.$parent.$on("refresh", this.fetchData);
  },
  beforeDestroy() {
    this.$parent.$off("refresh", this.fetchData);
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    filteredColumns() {
      if (this.$route.name === "PublicShauchalayaBranchRegister") {
        return this.columns.filter(
          (column) => !["actions"].includes(column.key)
        );
      }
      return this.columns;
    },
  },
  methods: {
    fetchData(page = 1) {
      PublicShauchalaya.getBranchList(page)
        .then((response) => {
          const branches = response.data.data;
          const formattedData = branches.map((branch, index) => ({
            key: branch.id,
            sn: (page - 1) * this.pageSize + index + 1,
            organization_name: branch.organization.organization_name,
            location: branch.location,
            toilet_type: branch.toilet_type,
            category: branch.category,
            status: branch.status ? "Active" : "Inactive",
            service_type: branch.service_type,
          }));
          this.data = formattedData;
          this.total = response.data.meta.total;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.fetchData(page);
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handleView(id) {
      this.$router.push(`/branch/${id}`);
    },
    handleDelete(id) {
      // if (confirm("तपाईं पक्का यो शाखा मेट्न चाहनुहुन्छ?")) {
      PublicShauchalaya.deleteBranch(id)
        .then((response) => {
          this.fetchData();
          message.success("शौचालय सफलतापूर्वक मेटिएको छ!");
        })
        .catch((error) => {
          message.error("शौचालय मेटाउन समस्या आएको छ!");
          console.error("Error deleting branch:", error);
        });
      // }
    },
    handleEdit(id) {
      // console.log('Editted')
    },
  },
};
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.cont {
  border: 1px solid rgb(210, 210, 210);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.headerTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(210, 210, 210);
  padding: 4px 12px;
}

.loader {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.heading {
  color: #2d67bd;
  font-size: 16px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 4px;
}

.capitalize {
  text-transform: capitalize;
}

.actionBtn {
  background-color: transparent;
  border: none;
  font-size: larger;
  box-shadow: none;
}

.tableNPagination {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: hidden;
}
</style>
