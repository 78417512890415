<template>
  <div class="review-cont">
    <h1 style="margin: 8px 0px;">समीक्षा सूची</h1>
    <!-- <div v-if="loading" class="loader">लोड हुँदैछ...</div> -->
    <a-spin v-if="loading" class="loader" :spinning="loading" tip="लोड हुँदैछ..."></a-spin>
    <div v-else class="reviews-list">
      <div v-for="review in reviews" :key="review.id" class="review-card">
        <div class="review-header">
          <a-rate class="rating" :value="parseInt(review.rating)" disabled />
          <div class="organization-info">
            <p style="font-size: small;">
              <span style="font-weight: bold;">शौचालय ठेगाना: </span>{{ review.branch.location }}
            </p>
            <p style="font-size: small;">
              <span style="font-weight: bold;">व्यवस्थापक संस्था: </span>
              <img :src="review.branch.organization_image" alt="logo" class="organization-logo" />
              {{ review.branch.organization_name }}
            </p>
          </div>
        </div>
        <p>{{ review.description }}</p>
      </div>
    </div>
    <a-pagination :current="currentPage" :pageSize="pageSize" :total="totalReviews" @change="handlePageChange"
      style="text-align: center; margin-top: 20px;" />
  </div>
</template>

<script>
import { Rate, Pagination } from 'ant-design-vue';
import PublicShauchalaya from '../../services/PublicShauchalaya';

export default {
  components: {
    'a-rate': Rate,
    'a-pagination': Pagination,
  },
  data() {
    return {
      reviews: [],
      currentPage: 1,
      pageSize: 10,
      totalReviews: 0,
      loading: true,
    };
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    getReviews(page = 1) {
      PublicShauchalaya.getAllReviewsList(page)
        .then(response => {
          this.reviews = response.data.data;
          this.totalReviews = response.data.meta.total; // Assuming the total number of reviews is provided as 'total'
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.getReviews(page);
    }
  }
};
</script>


<style scoped>
.review-cont {
  width: 100%;
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 8px;
}

.reviews-list {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
}

.review-card {
  background: white;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
  width: 49.5%;
  min-height: 150px;
}

.review-header {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 8px;
}

.organization-logo {
  width: 25px;
  height: 25px;
  margin-right: 4px;
}

.organization-info {
  display: flex;
  flex-direction: column;
}

.organization-info h3 {
  margin: 0;
  font-size: 16px;
}

.review-item p {
  margin: 0;
}

.loader{
  display: flex;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 800px) {
  .review-card {
    width: 99%;
  }
}
</style>
